import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { v4 as uuidv4 } from "uuid";

import { Navigation } from "swiper";
import { type FC } from "react";
import "./Swipers.scss";
import { ImageType } from "../../../store/productSlice/type";
import { getImagePath } from "../../../services/image-path";

export interface SwiperProps {
	images?: ImageType[];
}

export interface SwiperComponent extends FC<SwiperProps> {}

export const Swipers: SwiperComponent = ({ images }) => {
	return (
		<Swiper
			slidesPerView={1}
			centeredSlides={false}
			loop={true}
			effect={"slide"}
			navigation={true}
			modules={[Navigation]}
			className="mySwiper"
		>
			{images?.map(({ url }) => (
				<SwiperSlide key={uuidv4()}>
					<img className="image" src={getImagePath(url)} alt="" />
				</SwiperSlide>
			))}
		</Swiper>
	);
};
