import { useRef, useEffect, FC } from "react";
import classes from "./Cursor.module.scss";

export const Cursor: FC = () => {
	const cursorRef = useRef<HTMLDivElement>(null);
	const animationFrame = useRef<number | null>(null);

	useEffect(() => {
		const handleMouseMove = (event: MouseEvent) => {
			const { clientX, clientY } = event;
			const mouseX = clientX - (cursorRef.current?.clientWidth || 0) / 2;
			const mouseY = clientY - (cursorRef.current?.clientHeight || 0) / 2;

			animationFrame.current = requestAnimationFrame(() => {
				if (cursorRef.current) {
					cursorRef.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
				}
			});
		};

		const handleMouseLeave = () => {
			if (cursorRef.current) {
				cursorRef.current.style.display = "none";
			}
		};

		const handleMouseEnter = () => {
			if (cursorRef.current) {
				cursorRef.current.style.display = "block";
			}
		};

		const handleMouseOver = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			const isHovering =
				["H2", "IMG", "BUTTON", "A", "svg", "path", "INPUT", "LABEL"].includes(
					target.tagName
				) ||
				(target.tagName === "SPAN" && target.classList.contains("hoverSpan")) ||
				(target.tagName === "DIV" && target.classList.contains("hoverDiv"))

			const isExcludedClass = target.classList.contains(classes.excludeHover);

			if (cursorRef.current) {
				if (isHovering && !isExcludedClass) {
					cursorRef.current.classList.add(classes.hover);
				} else {
					cursorRef.current.classList.remove(classes.hover);
					cursorRef.current.classList.remove(classes.excludeHover);
				}

				// Добавление ховера на стрелку Swiper
				if (
					target.classList.contains("swiper-button-prev") ||
					target.classList.contains("swiper-button-next")
				) {
					cursorRef.current.classList.add(classes.swiperHover);
				} else {
					cursorRef.current.classList.remove(classes.swiperHover);
				}
			}
		};
		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseleave", handleMouseLeave);
		document.addEventListener("mouseenter", handleMouseEnter);
		document.addEventListener("mouseover", handleMouseOver);

		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseleave", handleMouseLeave);
			document.removeEventListener("mouseenter", handleMouseEnter);
			document.removeEventListener("mouseover", handleMouseOver);

			if (animationFrame.current) {
				cancelAnimationFrame(animationFrame.current);
			}
		};
	}, []);

	return <div className={classes.cursor} ref={cursorRef} />;
};
