import type { FC } from "react";
import classes from "./UserAgreement.module.scss";
import { Animated } from "../../shared/components/Animated/Animated";

export const UserAgreement: FC = () => {
	return (
		<Animated className={classes.wrapper}>
				<div className={classes.content}>
					<h1 className={classes.title}>пользовательское соглашение</h1>
				</div>
				<div className={classes.text}>
					<p>сайт носит информационный характер и не является публичной офертой, определяемой положениями статьи 437(2) гражданского кодекса РФ.</p>
					<p>выполняя любое из действий - оформляя заказы, подавая заявки, вы подтверждаете своё безоговорочное согласие с данным условием. если вы не согласны, вы не можете оформить заказ/заявку.</p>
				</div>
		</Animated>
	);
};
