import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { FormState } from "./type";
import { getUrl } from "../../services/get-url";

export const postOrder = createAsyncThunk<
	void,
	FormState,
	{ rejectValue: { message: string } }
>("pay/postFormData", async (data, thunkAPI) => {
	try {
		const response = await axios.post(
			getUrl(`/api/orders/`),
			data
		);
		return response.data;
	} catch (error) {
		let errorMessage: string;
		if (axios.isAxiosError(error) && error.response) {
			const errorMessages = error.response.data.email as string[];
			errorMessage = errorMessages[0];
		} else {
			errorMessage = (error as { email: string }).email;
		}

		return thunkAPI.rejectWithValue({ message: errorMessage });
	}
});
