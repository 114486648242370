import type { FC } from "react";
import classes from "./Sell.module.scss";
import { Animated } from "../../shared/components/Animated/Animated";

export const Sell: FC = () => {
	return (
		<Animated>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<div className={classes.text}>
						<h1 className={classes.title}>продать аксессуар</h1>
						<span className={classes.first}>
							Сегодня в мире люксовых брендов масса подделок, выявить которые
							могут только профессионалы. Мы ценим ваше доверие и тщательно
							проверяем каждый аксессуар.
						</span>
						<span className={classes.second}>
							Для проверки подлинности мы проводим собственную экспертизу и
							используем надежные сервисы, такие как Entrupy, Authenticate
							First, Authentic, Bababebi, в зависимости от модели и бренда. Эти
							платформы аутентификации определяют подделки с высочайшей
							точностью и работают с самыми известными мировыми
							ресейл-платформами по продаже аксессуаров.
						</span>
						<span className={classes.third}>
							Продавцы, которые намеренно пытаются продать неоригинальные
							изделия через THE CULTT, более не будут иметь возможности
							пользоваться нашей платформой для продажи своих вещей.
						</span>
						<a
							className={classes.button}
							href="t.me/manitruststore"
							target="_blank"
						>
							перейти в telegram
						</a>
					</div>
					<img src="images/woman.png" alt="" />
				</div>
			</div>
		</Animated>
	);
};
