import { ProductModel } from "./type";

export const groupProductsByProductCode = (products: ProductModel[]): ProductModel[] => {
    const productCodes = new Set();

    const groupedByProductCode = products.reduce((result, curr) => {
      if (!productCodes.has(curr.productCode)) {
        productCodes.add(curr.productCode);

        result.push(curr);
      }
      
      return result;
    }, [] as ProductModel[]);

    return groupedByProductCode;
}

export const isNewCondition = (condition: string) => {
  return condition === 'new';
}