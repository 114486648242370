import { useState, type FC, useRef, useEffect } from "react";
import classes from "./Delivery.module.scss";
import { Navlink } from "../../shared/components/Navlink/Navlink";
import { Animated } from "../../shared/components/Animated/Animated";
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";

export interface linkType {
	key: string;
	text: string;
}

type DeliveryProps = {
	activeLink: "delivery" | "payment",
}

export const Delivery = (props: DeliveryProps) => {
	const [activeLink, setActiveLink] = useState<string>(props.activeLink);
	const underlineRef = useRef<HTMLSpanElement>(null);

	const { isMobile }: any = useMatchMedia();

	const link: linkType[] = [
		{ key: "delivery", text: "доставка" },
		{ key: "payment", text: "оплата" },
	];

	const handleLinkClick = (key: string, dimensions: DOMRect) => {
		setActiveLink(key);
		if (underlineRef.current && isMobile) {
			underlineRef.current.style.left = `${dimensions.left - 16}px`;
			underlineRef.current.style.width = `${dimensions.width}px`;
		}
	};

	useEffect(() => {
		if (underlineRef.current && isMobile) {
			if (activeLink === 'payment') {
				underlineRef.current.style.left = "75.6px";
				underlineRef.current.style.width = "43.2px";
			} else {
				underlineRef.current.style.left = "0px";
				underlineRef.current.style.width = "58px";
			}
		}
	}, [activeLink, isMobile]);

	return (
		<Animated className={classes.wrapper}>
				<h1 className={classes.title}>доставка и оплата</h1>
				<div className={classes.content}>
					<div className={classes.links}>
						{link.map(({ key, text }) => (
							<Navlink
								key={key}
								active={activeLink === key}
								onClick={dimensions => handleLinkClick(key, dimensions)}
								text={text}
							/>
						))}

						{isMobile && (
							<span className={classes.underline} ref={underlineRef} />
						)}
					</div>

					{activeLink === "delivery" && (
						<div className={classes.text}>
							<p>- доставка по Москве силами собственного курьера платформы man I trust</p>
							<p>* стоимость доставки в пределах МКАД - бесплатно</p>
							<p>* заказы с доставкой по Москве доставляются в день заказа, при условии оформления заказа до 15:00 / Пн - Сб</p>
							<p>* заказы, оформленные позже 15:00, доставляются по Москве в течение следующего дня</p>
							<p>* заказы по Москве за пределы МКАД, на данный момент, не осуществляются</p>

							<br />
							<p>- услуга выездной примерки по Москве силами собственного курьера платформы man I trust</p>
							<p>* услуга доступна в пределах МКАД</p>
							<p>* услуга выездной примерки по Москве осуществляется в день заказа, при условии ее оформления до 15:00 / Пн - Сб</p>
							<p>* услуга выездной примерки, оформленная позже 15:00, осуществляется по Москве в течение следующего дня</p>
							<p>* услуга включает в себя возможность заказа не более 4-х товаров</p>
							<p>* услуга выездной примерки подразумевает возможность увидеть и примерить товар, а главное, принять взвешенное и обдуманное решение о его покупке</p>
							<p>* стоимость услуги - 1.700 руб</p>
							<p>* время, выделенное для примерки - 45 минут / если вам требуется больше времени, необходимо будет оплатить двойную стоимость примерки (3.400 руб)</p>


							<p>* услуга распространяется исключительно на ассортимент, представленный на сайте (не применяется в рамках индивидуального заказа из Европы)</p>
							<p style={{ fontWeight: 400}}>* курьер находится в зоне видимости клиента</p>
							<p style={{ fontWeight: 400}}>* при условии покупки - услуга бесплатна</p>

							<br />
							<p>- доставка по России и миру силами наших партнеров</p>
							<p>* в настоящий момент недоступна</p>
							<p>* вы можете воспользоваться функцией уведомить, чтобы первыми узнать об изменениях</p>
							<br />
							<br />
						</div>
					)}
					
					{activeLink === "payment" && (
						<div className={classes.text}>
							<p>- оплата наличным расчетом</p>
							<p>* оплата наличными или переводом осуществляется курьеру, при получении* мы принимаем оплату в следующих валютах: RUB / EUR / USDT</p>
							<p>* для оплаты средствами EUR и USDT, необходимо заранее уточнить стоимость товара в соответствии с удобной для вас валютой</p>
							<p>* перед покупкой вы можете осмотреть, примерить и проверить товар в рамках услуги выездной примерки и, если товар вам не подойдет, вы имеете право отказаться от его приобретения</p>
							<br />
							<p>- оплата безналичным расчетом</p>
							<p>* оплата на сайте или через терминал в настоящий момент недоступна</p>
							<p>* вы можете воспользоваться функцией уведомить, чтобы первыми узнать об изменениях</p>
						</div>
					)}
				</div>
		</Animated>
	);
};
