import type { FC } from "react";
import classes from "./Back.module.scss";
import { Animated } from "../../shared/components/Animated/Animated";

export const Back: FC = () => {
	return (
		<Animated className={classes.wrapper}>
				<div className={classes.content}>
					<h1 className={classes.title}>условия возврата</h1>
				</div>
				<div className={classes.text}>
					<p>на данном этапе, мы не принимаем возвраты после успешного совершения сделки, но предоставляем вам возможность принятия взвешенного решения о приобретении — услугой выездной примерки, отказом от покупки и временным ограничением доставки за пределами Москвы</p>
					<p>вы можете воспользоваться функцией <span className="underlinedLink">уведомить</span>, чтобы первыми узнать об изменениях в наших возможностях</p>
				</div>
		</Animated>
	);
};
