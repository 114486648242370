import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./categorySlice/slice";
import brandReducer from "./brandSlice/slice";
import productReducer from "./productSlice/slice";
import cartReducer from "./cartSlice/slice";
import favoriteReducer from "./favoriteSlice/slice";
import formReducer from "./formSlice/slice";

export const store = configureStore({
	reducer: {
		category: categoryReducer,
		brand: brandReducer,
		product: productReducer,
		cart: cartReducer,
		favorite: favoriteReducer,
		form: formReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
