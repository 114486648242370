import { FC, useState } from "react";
import classes from "./Summary.module.scss";
import { useAppSelector } from "../../shared/hooks/useAppSelector";
import { cartSelector, selectCartTotal } from "../../store/cartSlice/selector";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "../../shared/Icon/Icon";
import { getImagePath } from "../../services/image-path";
import { formatPrice } from "../../services/format-price";
import { useAppDispatch } from "../../shared/hooks/useAppDispatch";
import { checkAndAddPromocode } from "../../store/cartSlice/slice";

export const Summary: FC = () => {
	const dispatch = useAppDispatch();

	const {
		items,
		isFitting,
	} = useAppSelector(cartSelector);

	const {
		totalSum,
		totalDiscount,
		totalPromocodeDiscount,
		totalDelivery,
	} = useAppSelector(selectCartTotal);

	const [inputValue, setInputValue] = useState("");
	const [showInput, setShowInput] = useState(false);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	}

	const handleApplyPromocode = () => {
		dispatch(checkAndAddPromocode(inputValue));
		setShowInput(false);
		setInputValue("");
	}

	return (
		<div className={classes.summary}>
			<h1 className={classes.title}>Итого</h1>
			<div className={classes.cards}>
				{items.map(
					({ condition, productType, price, images, brand }) => (
						<div key={uuidv4()} className={classes.card}>
							<section>
								<img src={getImagePath(images[0].url)} alt="#" />
								<div className={classes.first}>
									<span className={classes.packing}>{productType}</span>
									<span className={classes.state}>{condition}</span>
								</div>
							</section>

							<div className={classes.second}>
								<span className={classes.name}>{brand.name}</span>
								<span className={classes.price}>
									<Icon name="ruble" className={classes.ruble} size={12} />
									{formatPrice(price)}
								</span>
							</div>
						</div>
					)
				)}
			</div>
			<div className={classes.order}>
				<div className={classes.delivery}>
					доставка{" "}
					<span>
						<Icon name="ruble" className={classes.ruble} size={12} /> 0
					</span>
				</div>
				{isFitting && (
					<div className={classes.delivery}>
						выездная примерка{" "}
						<span>
							<Icon name="ruble" className={classes.ruble} size={12} />
							1700
						</span>
					</div>
				)}
				{totalDiscount > 0 && (
					<div className={classes.sale}>
						скидка{" "}
						<span>
							<Icon name="ruble" className={classes.ruble} size={12} />
							-{formatPrice(totalDiscount)}
						</span>
					</div>
				)}
				{totalPromocodeDiscount > 0 && (
					<div className={classes.sale}>
						промокод{" "}
						<span>
							<Icon name="ruble" className={classes.ruble} size={12} />
							-{formatPrice(totalPromocodeDiscount)}
						</span>
					</div>
				)}
			</div>

			<div className={classes.result}>
				итог{" "}
				<span className={classes.price}>
					<Icon name="ruble" className={classes.ruble} size={12} />
					<span>
						{formatPrice(totalSum + totalDelivery - totalDiscount - totalPromocodeDiscount)}
					</span>
				</span>
			</div>

			<div className={classes.buttons}>
				{showInput ? (
					<div className={classes.activate}>
						<input type="text" value={inputValue} onChange={handleInputChange} />
						<button
							type="button"
							onClick={handleApplyPromocode}
							className={classes.button}>
								Применить
						</button>
					</div>
				) : (
					<button
						className={classes.promocode}
						onClick={() => setShowInput(true)}
					>
						промокод
					</button>
				)}
				<button type="submit" className={classes.pay}>сделать заказ</button>
			</div>
		</div>
	);
};
