import { useState, type FC, useCallback, useEffect, useMemo } from "react";
import classes from "./Cart.module.scss";
import { Animated } from "../../shared/components/Animated/Animated";
import { Empty } from "../../shared/components/Empty/Empty";
import { useAppSelector } from "../../shared/hooks/useAppSelector";
import { cartSelector, selectCartTotal } from "../../store/cartSlice/selector";
import { useAppDispatch } from "../../shared/hooks/useAppDispatch";
import { CartItem } from "../../shared/components/CartItem/CartItem";
import { removeItemFromCart, setFitting } from "../../store/cartSlice/slice";
import { Link, useNavigate } from "react-router-dom";
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";
import { Icon } from "../../shared/Icon/Icon";
import { Tooltip } from "../../shared/components/Tooltip/Tooltip";
import clsx from "clsx";
import { Radio } from "../../shared/components/Radio/Radio";
import { getImagePath } from "../../services/image-path";
import { setDeliveryType, setPaymentType } from "../../store/formSlice/slice";
import { formatPrice } from "../../services/format-price";

export const Cart: FC = () => {
	const {
		items,
		isFitting
	} = useAppSelector(cartSelector);

	const {
		totalSum,
		totalDiscount,
		totalDelivery,
	} = useAppSelector(selectCartTotal);

	const dispatch = useAppDispatch();

	const removeItem = useCallback((id: number) => {
		dispatch(removeItemFromCart(id));
	}, [dispatch]);

	const paymentOptions = [
		{ value: "cash_on_delivery", text: "наличными при получении (Москва)" },
		{ value: "online", text: "на сайте" },
		{ value: "installment", text: "рассрочка" },
		{ value: "credit", text: "сплит" },
	];

	const { isMobile }: any = useMatchMedia();

	const [deliveryMethod, setDeliveryMethod] = useState<string | null>(null);
	const [paymentMethod, setPaymentMethod] = useState<string | null>('x');
	const [error, setError] = useState<string | null>(null);
	const [lastScrollTop, setLastScrollTop] = useState(0);
	const [isLineVisible, setIsLineVisible] = useState(true);
	const navigate = useNavigate();

	const handleOrder = useCallback(() => {
		if (!deliveryMethod || !paymentMethod) {
			setError("Пожалуйста, выберите способ доставки и оплаты");
		} else {
			setError(null);
			navigate("/pay");
		}
	}, [deliveryMethod, paymentMethod, navigate]);

	const handleDeliveryChange = (method: string) => {
		setDeliveryMethod(method);

		dispatch(setDeliveryType(method));
		dispatch(setPaymentType('cash_on_delivery'));
	};

	useEffect(() => {
		const contentElement = document.querySelector(`.${classes.content}`);
		if (!contentElement) return;

		const contentTop =
			contentElement.getBoundingClientRect().top + window.pageYOffset;

		function handleScroll() {
			let st = window.pageYOffset || document.documentElement.scrollTop;

			if (st > contentTop) {
				setIsLineVisible(false);
			} else {
				setIsLineVisible(true);
			}

			setLastScrollTop(st <= 0 ? 0 : st);
		}

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [lastScrollTop]);

	const lineClassName = useMemo(
		() => clsx(classes.line, { [classes.out]: !isLineVisible }),
		[isLineVisible]
	);

	return (
		<Animated className={classes.wrapper}>
				<h1 className={classes.title}>
					Корзина {items.length ? `(${items.length})` : ""}{" "}
					{items.length !== 0 && (
						<Link className={classes.continue} to={`/`}>
							продолжить шоппинг
						</Link>
					)}
				</h1>

				{items.length === 0 ? (
					<Empty className={classes.empty}>
						добавьте желаемые товары в корзину и они появятся здесь
					</Empty>
				) : !isMobile ? (
					<div className={classes.all}>
						<div className={classes.carts}>
							{items.map(
								({
									id,
									title,
									productCode,
									categories,
									images,
									price,
									condition,
									productType,
									size,
									brand,
									discount
								}) => (
									<CartItem
										brand={brand?.name}
										key={id}
										id={id}
										productCode={productCode}
										productCategory={categories?.[0].name}
										title={title}
										image={getImagePath(images[0].url)}
										price={price}
										state={condition}
										productType={productType}
										size={size}
										discount={discount}
										onClick={() => removeItem(id)}
									/>
								)
							)}
						</div>
						<div className={classes.summary}>
							<h1 className={classes.title}>Итого</h1>
							<div className={classes.content}>
								<div className={classes.sum}>
									<div>промежуточный итог</div>{" "}
									<span>
										<Icon name="ruble" size={12} />{" "}
										{formatPrice(totalSum)}
									</span>
								</div>
								<div className={classes.delivery}>
									доставка{" "}
									<span>
										<Icon name="ruble" size={12} /> 0
									</span>
								</div>
								{totalDiscount > 0 && (
									<div className={classes.sale}>
										скидка{" "}
										<span>
											<Icon name="ruble" size={12} />
											-{formatPrice(totalDiscount)}
										</span>
									</div>
								)}
								{isFitting && (
									<div className={classes.delivery}>
										выездная примерка{" "}
										<span>
											<Icon name="ruble" size={12} />
											1700
										</span>
									</div>
								)}
							</div>

							<div className={classes.result}>
								итог{" "}
								<span>
									<Icon name="ruble" size={12} />
									{formatPrice(totalSum + totalDelivery - totalDiscount)}
								</span>
							</div>

							<button onClick={handleOrder} className={classes.button}>
								оформить заказ
							</button>

							<div className={classes.end}>
								<h1 className={classes.title}>
									доставка
									<Tooltip
										className={classes.tooltip}
										tooltip="обращайте внимание на условия доступных вариантов доставки"
									>
										<Icon name="allow" size={12} />
									</Tooltip>
								</h1>

								<Radio
									name="order"
									value="fitting"
									currentMethod={deliveryMethod}
									onChange={value => {
										handleDeliveryChange(value);
										if (value === "fitting") dispatch(setFitting(true));
									}}
									text="выездная примерка (Москва)"
									span="услуга выездной примерки в пределах МКАД – 1.700 руб / не более 4-х товаров в заказе / при условии приобретения товара – услуга бесплатна"
								/>

								<Radio
									name="order"
									value="free_delivery"
									currentMethod={deliveryMethod}
									onChange={value => {
										handleDeliveryChange(value);
										if (value === "free_delivery") dispatch(setFitting(false));
									}}
									text="бесплатная доставка (Москва)"
									span="доставка по Москве силами нашего курьера / в пределах МКАД"
								/>
							</div>
							<div className={classes.pay}>
								<h1 className={classes.title}>
									оплата{" "}
									<Tooltip
										className={classes.tooltip}
										tooltip="обращайте внимание на условия доступных вариантов оплаты"
									>
										<Icon name="allow" size={12} />
									</Tooltip>
								</h1>
								{deliveryMethod !== "fitting" ? (
									paymentOptions.map(option => (
										<Radio
											key={option.value}
											isDisabled={option.value !== 'cash_on_delivery'}
											name="pay"
											value={option.value}
											currentMethod={paymentMethod}
											onChange={setPaymentMethod}
											text={option.text}
											span="оплата наличными или переводом осуществляется курьеру, при получении (RUB / EUR / USDT)"
										/>
									))
								) : (
									<Radio
										name="pay"
										value="cash_on_delivery"
										currentMethod={paymentMethod}
										onChange={setPaymentMethod}
										text="наличными при получении (Москва)"
										span="оплата наличными или переводом осуществляется курьеру, при получении (RUB / EUR / USDT)"
									/>
								)}

								{error && <div className={classes.error}>{error}</div>}
							</div>
							<a href="tel:8 (969) 112-20-20" className={classes.phone}>
								{" "}
								<span>8 (969) 112-20-20</span>
								<h1 className={classes.help}>
									<Icon name="phone" />
									нужна помощь?
								</h1>
							</a>
						</div>
					</div>
				) : (
					<div className={classes.mobile}>
						<div className={classes.content}>
							<div className={classes.sum}>
								<div>промежуточный итог</div>{" "}
								<div className={classes.price}>
									<Icon name="ruble" size={0} />
									
									{formatPrice(totalSum)}
								</div>
							</div>
							<div className={classes.delivery}>
								доставка{" "}
								<div className={classes.price}>
									{" "}
									<Icon name="ruble" size={12} /> 0
								</div>
							</div>{" "}
							{totalDiscount > 0 && (
								<div className={classes.sale}>
									скидка{" "}
									<div className={classes.price}>
										<Icon name="ruble" size={12} />
										-{formatPrice(totalDiscount)}
									</div>
								</div>
							)}
							{isFitting && (
								<div className={classes.delivery}>
									выездная примерка{" "}
									<div className={classes.price}>
										{" "}
										<Icon name="ruble" size={12} /> 1700
									</div>
								</div>
							)}
							
							<div className={classes.result}>
								итог{" "}
								<div className={classes.price}>
									<Icon name="ruble" size={12} />{" "}
									{formatPrice(totalSum + totalDelivery - totalDiscount)}
								</div>
							</div>{" "}
							<span className={lineClassName} />
						</div>

						<div className={classes.carts}>
							{items.map(
								({
									id,
									productCode,
									categories,
									title,
									images,
									price,
									condition,
									productType,
									size,
									brand,
									discount
								}) => (
									<CartItem
										brand={brand.name}
										key={id}
										id={id}
										productCode={productCode}
										productCategory={categories?.[0].name}
										title={title}
										image={getImagePath(images[0].url)}
										price={price}
										state={condition}
										productType={productType}
										size={size}
										discount={discount}
										onClick={() => removeItem(id)}
									/>
								)
							)}
						</div>
						<div className={classes.summary}>
							<div className={classes.end}>
								<h1 className={classes.title}>
									доставка{" "}
									<Tooltip
										className={classes.tooltip}
										tooltip="обращайте внимание на условия доступных вариантов доставки"
									>
										<Icon name="allow" size={12} />
									</Tooltip>
								</h1>
								<Radio
									name="order"
									value="fitting"
									currentMethod={deliveryMethod}
									onChange={value => {
										handleDeliveryChange(value);
										if (value === "fitting") dispatch(setFitting(true));
									}}
									text="выездная примерка (Москва)"
									span="услуга выездной примерки в пределах МКАД – 1.700 руб / не более 4-х товаров в заказе / при условии приобретения товара – услуга бесплатна"
								/>

								<Radio
									name="order"
									value="free_delivery"
									currentMethod={deliveryMethod}
									onChange={value => {
										handleDeliveryChange(value);
										if (value === "free_delivery") dispatch(setFitting(false));
									}}
									text="бесплатная доставка (Москва)"
									span="доставка по Москве силами нашего курьера / в пределах МКАД"
								/>
							</div>
							<div className={classes.end}>
								<h1 className={classes.title}>
									оплата{" "}
									<Tooltip
										className={classes.tooltip}
										tooltip="обращайте внимание на условия доступных вариантов оплаты"
									>
										<Icon name="allow" size={12} />
									</Tooltip>
								</h1>
								{deliveryMethod !== "fitting" ? (
									paymentOptions.map(option => (
										<Radio
											key={option.value}
											name="pay"
											value={option.value}
											currentMethod={paymentMethod}
											onChange={setPaymentMethod}
											text={option.text}
											isDisabled={option.value !== 'cash_on_delivery'}
											span="оплата наличными или переводом осуществляется курьеру, при получении (RUB / EUR / USDT)"
										/>
									))
								) : (
									<Radio
										name="pay"
										value="cash_on_delivery"
										currentMethod={paymentMethod}
										onChange={setPaymentMethod}
										text="наличными при получении (Москва)"
										span="оплата наличными или переводом осуществляется курьеру, при получении (RUB / EUR / USDT)"
									/>
								)}
								{error && <div className={classes.error}>{error}</div>}
							</div>
							<a href="tel:8 (969) 112-20-20" className={classes.phone}>
								{" "}
								<span>8 (969) 112-20-20</span>
								<h1 className={classes.help}>
									<Icon name="phone" />
									нужна помощь?
								</h1>
							</a>

							<button className={classes.button} onClick={handleOrder}>
								оформить заказ
							</button>
						</div>
					</div>
				)}
		</Animated>
	);
};
