import { useMemo, FC, useState, useCallback, useRef } from "react";
import classes from "./Navbar.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useMatchMedia } from "../../hooks/useMatchMedia";
import { Icon } from "../../Icon/Icon";

export interface navbarType {
	name: string;
	link: string;
}

interface NavbarProps {
	isOpen: boolean;
	className: string;
	onNavbarClose: () => void;
}

export const Navbar: FC<NavbarProps> = ({
	isOpen,
	className,
	onNavbarClose,
}) => {
	const location = useLocation();

	const { isMobile }: any = useMatchMedia();

	const navbar: navbarType[] = isMobile
		? [
				{ name: "о нас", link: "/about-us" },
				{ name: "магазин", link: "/" },
				{ name: "подлинность", link: "/authenticity" },
				// { name: "продать", link: "/sell" },
				{ name: "индивидуальный заказ", link: "/individual-order" },
		  ]
		: [
				{ name: "магазин", link: "/" },
				{ name: "подлинность", link: "/authenticity" },
				{ name: "о нас", link: "/about-us" },
				// { name: "продать", link: "/sell" },
				{ name: "индивидуальный заказ", link: "/individual-order" },
		  ];

	const [isMounted] = useState(true);

	const [hovering, setHovering] = useState(false);
	let timeoutId = useRef<any>();

	const handleMouseEnter = useCallback(() => {
		setHovering(true);
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}
	}, []);

	const handleMouseLeave = useCallback(() => {
		setHovering(false);
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}
		timeoutId.current = setTimeout(() => {
			onNavbarClose();
		}, 5000);
	}, [onNavbarClose]);
	const navigate = useNavigate();

	const wrapperClassName = useMemo(
		() =>
			clsx(classes.wrapper, { [classes.open]: isOpen && isMounted }, className),
		[className, isOpen, isMounted]
	);

	const linkClassName = useMemo(() => clsx(classes.link, {}), []);

	return (
		<div
			className={wrapperClassName}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{navbar.map(({ name, link }, index) => (
				<Link
					className={clsx(linkClassName, {
						[classes.active]: location.pathname === link,
						[classes.last]: index === navbar.length - 1,
					})}
					onClick={event => {
						event.preventDefault();
						onNavbarClose();
						navigate(link);
					}}
					to={link}
					key={link}
				>
					{name}
				</Link>
			))}
		</div>
	);
};
