import { useMemo, useState, useEffect, useCallback } from "react";
import classes from "./ProductItem.module.scss";
import { Link } from "react-router-dom";
import { Icon } from "../../Icon/Icon";
import clsx from "clsx";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
	addItemToFavorite,
	removeItemFromFavorite,
} from "../../../store/favoriteSlice/slice";
import { toggleProductFavorite } from "../../../store/productSlice/slice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { favoriteSelector } from "../../../store/favoriteSlice/selector";
import { selectProductState } from "../../../store/productSlice/selector";
import { formatPrice } from "../../../services/format-price";

export type ParentType = {
	type: 'category' | 'brand';
	id: number,
}
export interface ProductItemProps {
	image: string;
	quality: string;
	inStock?: boolean,
	price: number;
	discountPercent?: number, 
	title: string;
	type?: "default" | "favorite";
	brand: string,
	className?: string;
	productId?: number;
	index: number;
	loadFrom?: ParentType,
	isOnlyItem?: boolean,
}

export const ProductItem = ({
	quality,
	inStock = true,
	price,
	discountPercent,
	title,
	image,
	brand,
	type = "default",
	className,
	productId,
	index,
	loadFrom,
	isOnlyItem = false,
}: ProductItemProps) => {
	const dispatch = useAppDispatch();

	const brandClassName = useMemo(
		() =>
			clsx(
				classes.brand, {
					[classes.odd]: Number(index) % 2 === 1 && !isOnlyItem
				},
				className
			),

		[className, index, isOnlyItem]
	);

	const removeItem = () => {
		dispatch(removeItemFromFavorite(Number(productId)));
		dispatch(toggleProductFavorite(Number(productId)));
	};

	const { favoriteItems } = useAppSelector(favoriteSelector);
	const { products } = useAppSelector(selectProductState);

	const isItemInFavorite = useMemo(
		() => favoriteItems.includes(Number(productId)),
		[favoriteItems, productId]
	);
	const [isFavorite, setIsFavorite] = useState(isItemInFavorite);

	const favoriteClassName = clsx(classes.icon, {
		[classes.favorite]: isFavorite,
	});

	useEffect(() => {
		setIsFavorite(isItemInFavorite);
	}, [isItemInFavorite]);

	const handleIsFavorite = useCallback(() => {
		if (!isFavorite) {
			const favoriteItem = products.find(item => item.id === productId);
			if (favoriteItem) {
				dispatch(addItemToFavorite(favoriteItem));
				setIsFavorite(true);
			}
		} else {
			dispatch(removeItemFromFavorite(Number(productId)));
			setIsFavorite(false);
		}
	}, [dispatch, products, isFavorite, productId]);

	const productLink = useMemo(() => {
		let searchParam = '?';

		if (loadFrom) {
			searchParam += `${loadFrom.type}Id=${loadFrom.id}`
		}
		return `/product/${productId}${searchParam}`;
	}, [loadFrom, productId])

	return (
		<li className={brandClassName} key={productId}>
			<Link to={productLink}>
				<img
					className={type === "default" ? classes.image : classes.fav_image}
					src={image}
					alt=""
				/>
			</Link>

			{type === "default" ? (
				<Icon
					onClick={handleIsFavorite}
					size={18}
					className={favoriteClassName}
					name="heart"
				/>
			) : (
				<button className={classes.button} onClick={() => removeItem()} />
			)}
			
			<span className={classes.quality}>{quality}</span>
			<div className={classes.titleContainer}>
				<span className={classes.brandName}>
					{brand}
				</span>
				<span className={classes.productName}>{title}</span>
			</div>
			<div className={classes.priceContainer}>

				{!inStock && (
					<span className={classes.price}>
						нет в наличии
					</span>	
				)}

				{inStock && (
					<>
						<span className={clsx(
							classes.price, {
								[classes.striked]: !!discountPercent
							}
							)}>
							{formatPrice(price)}
							{" "}руб
						</span>
		
						{!!discountPercent && (
							<>
								<span className={clsx(
									classes.price,
								)}>
									{`-${discountPercent}%`}
								</span>
		
								<span className={clsx(
									classes.price,
									classes.salePrice
								)}>
									{formatPrice(Math.floor(price * (100 - discountPercent) / 100))}
									<span className={classes.ruble}>руб</span>
								</span>
							</>
						)}
					</>
				)}
			</div>
		</li>
	);
};
