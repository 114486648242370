import { FC, useMemo } from "react";

import { favoriteSelector } from "../../store/favoriteSlice/selector";

import { addItemToCart, removeItemFromCart } from "../../store/cartSlice/slice";
import { Animated } from "../../shared/components/Animated/Animated";
import { Empty } from "../../shared/components/Empty/Empty";
import classes from "./Favorites.module.scss";
import { useAppSelector } from "../../shared/hooks/useAppSelector";
import { useAppDispatch } from "../../shared/hooks/useAppDispatch";
import clsx from "clsx";
import { selectAddedItemsIds } from "../../store/cartSlice/selector";
import { removeItemFromFavorite } from "../../store/favoriteSlice/slice";
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";
import { motion } from "framer-motion";
import { ProductItem } from "../../shared/components/ProductItem/ProductItem";
import { ProductModel } from "../../store/productSlice/type";
import { FavoriteItem } from "../../store/favoriteSlice/type";
import { getImagePath } from "../../services/image-path";

export const Favorites: FC = () => {
	const { favorite, added } = useAppSelector(favoriteSelector);
	const addedItems = useAppSelector(selectAddedItemsIds);
	const dispatch = useAppDispatch();

	const addToCartHandler = (item: ProductModel) => {
		if (!addedItems.includes(item.id)) {
			dispatch(addItemToCart(item));
			dispatch(removeItemFromFavorite(item.id));
		} else {
			dispatch(removeItemFromCart(item.id));
		}
	};

	const favoritesClassName = useMemo(
		() =>
			clsx(classes.favorites, {
				[classes.one]: favorite.length === 1,
			}),
		[favorite]
	);

	const { isMobile }: any = useMatchMedia();

	return (
		<Animated className={classes.wrapper}>
			<h1 className={classes.title}>вишлист {added ? `(${added})` : ""}</h1>
			{favorite.length ? (
				<ul className={favoritesClassName}>
					{favorite.map((item: FavoriteItem, index: number) => {
						const hasDiscount = item.discount && !item.discount.promocode;
						const discountPercent = hasDiscount ? item.discount?.percentDiscount : undefined;

						return (
							<div className={classes.brand} key={item.id}>
								<ProductItem
									brand={item.brand.name}
									index={index + 1}
									productId={item.id}
									quality={item.condition}
									image={getImagePath(item.images[0]?.url)}
									inStock={!!!item.order}
									discountPercent={discountPercent}
									price={item.price}
									title={item.title}
									type="favorite"
									isOnlyItem={favorite.length <= 1}
								/>
								{isMobile &&
									(!addedItems.includes(item.id) ? (
										<button
											className={classes.button}
											onClick={() => addToCartHandler(item)}
										>
											в корзину
										</button>
									) : (
										<motion.button
											className={classes.button}
											whileTap={{ rotate: 30 }}
											transition={{ duration: 0.1 }}
										>
											в корзину
										</motion.button>
									))}
							</div>
						);
					})}
					
				</ul>
			) : (
				<Empty className={classes.empty}>
					добавьте понравившееся товары в вишлист и они появятся здесь
				</Empty>
			)}
		</Animated>
	);
};
