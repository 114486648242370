import { useState, FC, useRef, useEffect } from "react";
import classes from "./Question.module.scss";
import { Questions } from "../../components/Questions/Questions";
import { Navlink } from "../../shared/components/Navlink/Navlink";
import { Animated } from "../../shared/components/Animated/Animated";
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";

export interface linkType {
	key: string;
	text: string;
}

const link: linkType[] = [
	{ key: "first", text: "продаю" },
	{ key: "second", text: "покупаю" },
	{ key: "third", text: "аутентификация" },
	{ key: "fourth", text: "spa" },
];

export const Question: FC = () => {
	const [activeLink, setActiveLink] = useState<string>("first");
	const underlineRef = useRef<HTMLSpanElement>(null);

	const { isMobile }: any = useMatchMedia();

	const handleLinkClick = (key: string, dimensions: DOMRect) => {
		setActiveLink(key);
		if (underlineRef.current && isMobile) {
			underlineRef.current.style.left = `${dimensions.left - 16}px`;
			underlineRef.current.style.width = `${dimensions.width}px`;
		}
	};

	useEffect(() => {
		if (underlineRef.current && isMobile) {
			underlineRef.current.style.left = "0px";
			underlineRef.current.style.width = "43px";
		}
	}, [isMobile]);

	return (
		<Animated>
			<div className={classes.wrapper}>
				<h1 className={classes.title}>часто задаваемые вопросы</h1>
				<div className={classes.content}>
					<div className={classes.links}>
						{link.map(({ key, text }) => (
							<Navlink
								className={classes.navlink}
								key={key}
								active={activeLink === key}
								onClick={dimensions => handleLinkClick(key, dimensions)}
								text={text}
							/>
						))}
						{isMobile && (
							<span className={classes.underline} ref={underlineRef} />
						)}
					</div>
					<Questions index={activeLink} className={classes.question} />
				</div>
			</div>
		</Animated>
	);
};
