import { useMemo, type FC, useRef, useLayoutEffect, useState } from "react";
import classes from "./Radio.module.scss";
import clsx from "clsx";
import { motion } from "framer-motion";

export interface RadioProps {
	name: string;
	value: string;
	currentMethod: string | null;
	isDisabled?: boolean,
	text: string;
	span?: string;
	className?: string,
	onChange?: (value: string) => void;
}

export interface RadioComponent extends FC<RadioProps> {}

export const Radio: RadioComponent = ({
	name,
	value,
	currentMethod,
	onChange,
	isDisabled,
	text,
	span,
	className,
}) => {
	const radioRef = useRef<HTMLSpanElement>(null);

	const [elementWidth, setElementWidth] = useState(0);

	useLayoutEffect(() => {
		if (radioRef.current) {
			setElementWidth(radioRef.current.offsetWidth);
		}
	}, []);

	const checked = currentMethod === value;

	const fadeIn = {
		hidden: {
			opacity: 0,
			y: -10,
		},
		visible: {
			opacity: 1,
			y: 0,

			transition: {
				duration: 0.5,
			},
		},
	};

	const labelClassName = useMemo(
		() => clsx(
			classes.label, { 
				[classes.show]: checked 
			}),
		[checked]
	);

	return (
		<label className={labelClassName}>
			<input
				type="radio"
				name={name}
				className={classes.radio}
				disabled={isDisabled}
				onChange={() => onChange?.(value)}
				checked={checked}
			/>

			<div
				className={clsx(
					className,
					classes.option,
					'hoverDiv'
				)}>
				<span
					ref={radioRef}
					dangerouslySetInnerHTML={{
						__html: text
					}}/>
				
				{isDisabled && (
					<div
						style={{
							width: text.length * 8 + 16 // multiplying by 8 bcs one letter's width is ~ 7.5px
						}}
						className={classes.strike}>
					</div>
				)}
			</div>

			{!!span && (
				<motion.span
					initial="hidden"
					animate={checked ? "visible" : "hidden"}
					variants={fadeIn}
					className={classes.span}>
					{span}
				</motion.span>
			)}
		</label>
	);
};
