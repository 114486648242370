import { useState, type FC, useCallback, useEffect, useMemo } from "react";
import classes from "./Pay.module.scss";
import { Summary } from "../../components/Summary/Summary";
import { postOrder } from "../../store/formSlice/thunk";
import { useAppDispatch } from "../../shared/hooks/useAppDispatch";
import { debounce } from "lodash";
import { useAppSelector } from "../../shared/hooks/useAppSelector";
import InputMask from "react-input-mask";

import {
	formSelector,
	orderPostError,
	orderPostStatus,
} from "../../store/formSlice/selector";
import { resetFormData, setFormData } from "../../store/formSlice/slice";
import { cartSelector } from "../../store/cartSlice/selector";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../../store/cartSlice/slice";
import { Icon } from "../../shared/Icon/Icon";
import { Animated } from "../../shared/components/Animated/Animated";
import clsx from "clsx";

export const Pay: FC = () => {
	const dispatch = useAppDispatch();
	const form = useAppSelector(formSelector);
	const postStatus = useAppSelector(orderPostStatus);
	const postError = useAppSelector(orderPostError);
	const [orderData, setOrderData] = useState({ ...form });

	const { items, isFitting } = useAppSelector(cartSelector);

	const navigate = useNavigate();

	const [phoneError] = useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setOrderData(prevOrderData => {
			const updatedOrderData = {
				...prevOrderData,
				[e.target.name]: e.target.value,
			};

			debouncedSave(updatedOrderData);
			return updatedOrderData;
		});
	};

	useEffect(() => {
		setOrderData({ ...form });
	}, [items, form, isFitting]);

	const debouncedSave = useCallback(
		debounce(data => dispatch(setFormData(data))),
		[]
	);
	const [submitted, setSubmitted] = useState(false);

	useEffect(() => {
		const productIds = items.map(product => product.id);
		setOrderData({ ...form, productIds });
	}, [form, items]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		let finalOrderData = orderData;

		if (isFitting) {
			finalOrderData = {
				...orderData,
				city: "Москва",
				zipCode: "Москва",
				region: "Москва",
			};
		}

		dispatch(postOrder(finalOrderData));
		if (postStatus === "succeeded" && phoneError === false) {
			setSubmitted(true);
		}
	};

	useEffect(() => {
		if (postStatus === "succeeded" && phoneError === false) {
			dispatch(clearCart());
			dispatch(resetFormData());
			setOrderData({ ...form });
			setSubmitted(false);
			navigate("/thankfull");
		}
	}, [postStatus, submitted, navigate, form, dispatch, phoneError]);

	useEffect(() => {
		return () => {
			setOrderData({ ...form });
			setSubmitted(false);
		};
	}, [form]);

	const inputClassName = useMemo(
		() => clsx(classes.input, { [classes.flitting]: isFitting }),
		[isFitting]
	);

	return (
		<Animated>
			<form className={classes.wrapper} onSubmit={handleSubmit}>
				<h1 className={classes.title}>
					доставка{" "}
					<a href="tel:8 (969) 112-20-20" className={classes.phone}>
						{" "}
						<span>8 (969) 112-20-20</span>
						<h1 className={classes.help}>
							<Icon name="phone" />
							нужна помощь?
						</h1>
					</a>
				</h1>
				<div className={classes.all}>
					<div className={classes.pay}>
						<div className={classes.first}>
							<section>
								<label htmlFor="">имя*</label>
								<input
									required
									className={classes.input}
									type="text"
									name="firstName"
									value={orderData.firstName}
									onChange={handleChange}
								/>{" "}
							</section>
							<section>
								<label htmlFor="">фамилия*</label>
								<input
									required
									className={classes.input}
									type="text"
									name="lastName"
									value={orderData.lastName}
									onChange={handleChange}
								/>
							</section>
						</div>
						<div className={classes.email}>
							<label
								style={
									postStatus === "failed"
										? { color: "#bb1a1a", fontWeight: "400" }
										: {}
								}
								htmlFor=""
							>
								email*
							</label>
							<input
								required
								className={classes.input}
								value={orderData.email}
								onChange={handleChange}
								type="email"
								name="email"
							/>
							{postStatus === "failed" && postError && (
								<div className={classes.error}>{postError}</div>
							)}{" "}
						</div>
						{!isFitting && (
							<div className={classes.content}>
								<section>
									<label htmlFor="">страна</label>
									<input
										className={classes.input}
										value={orderData.country}
										onChange={handleChange}
										type="text"
										name="country"
									/>{" "}
								</section>

								<section>
									<label htmlFor="">область*</label>
									<input
										required
										className={classes.input}
										type="text"
										name="region"
										value={orderData.region}
										onChange={handleChange}
									/>
								</section>
							</div>
						)}

						<div className={classes.content}>
							{!isFitting && (
								<section>
									<label htmlFor="">город*</label>
									<input
										required
										className={classes.input}
										value={orderData.city}
										onChange={handleChange}
										type="text"
										name="city"
									/>
								</section>
							)}
							<section>
								<label htmlFor="">улица*</label>
								<input
									required
									className={inputClassName}
									value={orderData.street}
									onChange={handleChange}
									type="text"
									name="street"
								/>
							</section>{" "}
						</div>

						<div className={classes.content}>
							<section>
								<label htmlFor="">дом*</label>
								<input
									required
									className={classes.input}
									value={orderData.house}
									onChange={handleChange}
									type="text"
									name="house"
								/>
							</section>
							<section>
								<label htmlFor="">квартира*</label>
								<input
									required
									className={classes.input}
									value={orderData.apartment}
									onChange={handleChange}
									type="text"
									name="apartment"
								/>
							</section>
						</div>
						<div className={classes.content}>
							{!isFitting && (
								<section>
									<label htmlFor="">индекс*</label>
									<input
										required
										className={classes.input}
										type="text"
										name="zipCode"
										value={orderData.zipCode}
										onChange={handleChange}
									/>
								</section>
							)}
							<section className={classes.telephone}>
								<label
									style={
										phoneError === true
											? { color: "#bb1a1a", fontWeight: "400" }
											: {}
									}
									htmlFor=""
								>
									телефон*
								</label>
								<InputMask
									required
									className={inputClassName}
									mask="+7 (999) 999-99-99"
									value={orderData.phone}
									onChange={handleChange}
									type="tel"
									name="phone"
								/>
								{phoneError && (
									<div className={classes.error}>
										Некорректный номер телефона
									</div>
								)}
							</section>
						</div>
						<div className={classes.commentary}>
							<label htmlFor="">комментарий</label>
							<input
								className={classes.input}
								value={orderData.comment}
								onChange={handleChange}
								type="text"
								name="comment"
							/>
						</div>
					</div>
					<Summary />
				</div>
			</form>
		</Animated>
	);
};
