import { useState } from "react";
import { Dialog } from "../Dialog/Dialog";
import classes from './NotifyDialog.module.scss';
import InputMask from "react-input-mask";
import { useAppDispatch } from "../../shared/hooks/useAppDispatch";
import { notifyAvailability } from "../../store/productSlice/thunk";
import { Radio } from "../../shared/components/Radio/Radio";
import clsx from "clsx";
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";

type NotifyDialogProps = {
    productId: number,
    productCode: string,
    onClose: () => void,
}
export const NotifyDialog = (props: NotifyDialogProps) => {
    const { isMobile }: any = useMatchMedia();
	const dispatch = useAppDispatch();

    const { onClose, productId, productCode, } = props;

	const [data, setData] = useState({
        fullName: "",
        phone: "",
        productId
    });

    const [policyAccepted, setPolicyAccepted] = useState("false");

    const [errors, setErrors] = useState({
        fullName: false,
        phone: false,
    });

    const handleAcceptPolicy = () => {
        setPolicyAccepted("true");
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setData(prevData => {
			const updatedData = {
				...prevData,
				[e.target.name]: e.target.value,
			};

			return updatedData;
		});
	};

    const handleSubmit = () => {
        if (data.fullName === "") {
            setErrors(e => ({
                ...e,
                fullName: true,
            }))

            return;
        }

        if (data.phone.length !== 18 || data.phone.includes('_')) {
            setErrors(e => ({
                ...e,
                phone: true,
            }))

            return;
        }

        if (policyAccepted !== 'true') {
            return;
        }

        dispatch(notifyAvailability(data))
        onClose();
    }

    const top = isMobile ? "10vh" : "calc(50vh - 287px)";
    const left = isMobile ? "calc(50vw - 174px)" : "calc(50vw - 231px)";

    return (
        <Dialog top={top} left={left}>
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <div className={classes.id}>ID {productCode}</div>
                    <div>
                        <button className={classes.closeButton} onClick={onClose} />
                    </div>
                </div>
                <div className={classes.content}>
                    <div className={classes.one}>
                        <p className={classes.text}>мы уведомим вас о поступлении интересующей вас позиции или вместе оформим индивидуальный заказ с лучшим предложением</p>
                    </div>
                    <div className={classes.two}>
                        <section className={classes.inputSection}>
                            <label htmlFor="" className={classes.label}>имя*</label>
                            <input
                                required
                                className={classes.input}
                                value={data.fullName}
                                onChange={handleChange}
                                type="text"
                                name="fullName"
                            />
                            <div className={clsx(
                                classes.error, {
                                    [classes.show]: !!errors.fullName,
                                }
                            )}>
                                поле не может быть пустым
                            </div>
                        </section>
                        <section>
                            <label htmlFor="" className={classes.label}>телефон* (whatsapp / telegram)</label>
                            <InputMask
                                required
                                className={classes.input}
                                mask="+7 (999) 999-99-99"
                                value={data.phone}
                                onChange={handleChange}
                                name="phone"
                            />
                            
                            <div className={clsx(
                                    classes.error, {
                                        [classes.show]: !!errors.phone,
                                    }
                                )}>
                                    Некорректно введен номер телефона
                                </div>
                        </section>
                        
                    </div>
                    <section className={classes.acceptanceWrapper}>
                        <Radio
                            name="pay"
                            value={policyAccepted}
                            currentMethod="true"
                            onChange={handleAcceptPolicy}
                            text="продолжая, вы соглашаетесь с нашей <span class='underlinedLink'>политикой обработки данных</span>"
                            className={classes.acceptance}
                        />
                    </section>
                </div>

                <div className={classes.buttonContainer}>
                    <button onClick={handleSubmit} className={classes.button}>
                        спасибо
                    </button>
                </div>
            </div>
        </Dialog>
    )
}