import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUrl } from "../../services/get-url";
import { NotificationRequestData } from "./type";

export const fetchProductsFromCategory = createAsyncThunk(
	"product/fetchProductsFromCategory",
	async (id: number | undefined) => {
		const response = await axios.get(
			getUrl(`/api/products?categoryId=${id}`),
		);
		return response.data;
	}
);

export const fetchProductsFromBrand = createAsyncThunk(
	"product/fetchProductsFromBrand",
	async (id: number | undefined) => {
		const response = await axios.get(
			getUrl(`/api/products?brandId=${id}`),
		);
		return response.data;
	}
);

export const fetchDiscountedProducts = createAsyncThunk(
	"product/fetchDiscountedProducts",
	async () => {
		const response = await axios.get(
			getUrl(`/api/products/discounted`),
		);
		return response.data;
	}
);

export const fetchAllProducts = createAsyncThunk(
	"product/fetchProductsFromBrand",
	async (id: number | undefined) => {
		const response = await axios.get(
			getUrl(`/api/products`),
		);
		return response.data;
	}
);

export const fetchOneProduct = createAsyncThunk(
	"product/fetchOneProduct",
	async (id: number | undefined) => {
		const response = await axios.get(
			getUrl(`/api/products/${id}`),
		);
		return response.data;
	}
);

export const notifyAvailability = createAsyncThunk(
	"product/notifyAvailability",
	async (data: NotificationRequestData) => {
		const response = await axios.post(
			getUrl(`/api/notifications`),
			data
		);
		return response.data;
	}
);
