import classes from './RunningLine.module.scss';

export const RunningLine = () => {
    const text = 'доставка по Москве осуществляется в день заказа, при условии оформления заказа до 15:00 (Пн - Сб) / ';

    return (
        <div className={classes.block}>
            <p>{text.repeat(6)}</p>
        </div>  
    )
}