import {
	useState,
	type FC,
	useEffect,
	useCallback,
	useMemo,
	useRef,
} from "react";
import classes from "./Product.module.scss";
import { Icon } from "../../shared/Icon/Icon";
import { Swipers } from "../../shared/components/Swipers/Swipers";
import { Animated } from "../../shared/components/Animated/Animated";
import { useAppDispatch } from "../../shared/hooks/useAppDispatch";
import { useAppSelector } from "../../shared/hooks/useAppSelector";

import { useParams } from "react-router";

import { addItemToCart, removeItemFromCart } from "../../store/cartSlice/slice";
import {
	addItemToFavorite,
	removeItemFromFavorite,
} from "../../store/favoriteSlice/slice";
import clsx from "clsx";
import { cartSelector, selectAddedItemsIds } from "../../store/cartSlice/selector";
import { favoriteSelector } from "../../store/favoriteSlice/selector";
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";
import { Accordion } from "../../shared/components/Accordion/Accordion";
import { Link, useSearchParams } from "react-router-dom";
import { Loader } from "../../shared/components/Loader/Loader";
import { More } from "../../shared/components/More/More";
import { motion } from "framer-motion";
import { fetchOneProduct, fetchProductsFromBrand, fetchProductsFromCategory } from "../../store/productSlice/thunk";
import { Price } from "../../components/Price/Price";
import { selectProductState, selectProductsToShow } from "../../store/productSlice/selector";
import { NotifyDialog } from "../../components/NotifyDialog/NotifyDialog";
import { isNewCondition } from "../../store/productSlice/helpers";
import { ProductModel } from "../../store/productSlice/type";
export interface detailsType {
	id: number;
	text: string;
	type: string;
	description: string;
}

export const details: detailsType[] = [
	{ id: 1, text: "детали", type: "details", description: "" },
	{ id: 2, text: "доставка", type: "delivery", description: "" },
	{ id: 3, text: "оплата", type: "pay", description: "" },
	{ id: 4, text: "возврат", type: "back", description: "" },
];

const infoVariants = {
	hidden: { opacity: 0, x: -100 },
	visible: { opacity: 1, x: 0 },
};

export const Product: FC = () => {
	const dispatch = useAppDispatch();

	const { productId } = useParams();

	const [searchParams] = useSearchParams();

	const [infoType, setInfoType] = useState("default");

	const { products, loading } = useAppSelector(selectProductState);
	const productsToShow = useAppSelector(selectProductsToShow);
	const { favoriteItems } = useAppSelector(favoriteSelector);
	const addedItems = useAppSelector(selectAddedItemsIds);

	const [showNotifyDialog, setShowNotifyDialog] = useState(false);

	const isProductInFavorite = useMemo(
		() => favoriteItems.includes(Number(productId)),
		[favoriteItems, productId]
	);

	const isProductInCart = useMemo(
		() => addedItems.includes(Number(productId)),
		[addedItems, productId]
	);
	const [isFavorite, setIsFavorite] = useState(isProductInFavorite);
	const [isAdded, setIsAdded] = useState(isProductInCart);

	const productItem = useMemo(() => {
		if (productId) {
			return products.find(item => item.id === parseInt(productId));
		}
	}, [productId, products]);

	const availableSizes = useMemo(() => {
		const sizeToProduct = new Map<string, ProductModel>();

		if (productItem && !!products.length) {
			products.forEach((product) => {
				if (product.productCode === productItem.productCode) {
					sizeToProduct.set(product.size, product);
				}
			})
		}

		return sizeToProduct;
	}, [productItem, products])

	const isNotInStock = productItem ? (!!productItem.order || productItem.isSold) : false;

	const hasDiscount = productItem?.discount && !productItem.discount.promocode;
	const discountPercent = hasDiscount ? productItem.discount?.percentDiscount : undefined;

	const handleNotify = () => {
		setShowNotifyDialog(false);
	}

	useEffect(() => {
		if (products.length === 0) {
			if (searchParams.has('categoryId')) {
				const categoryId = searchParams.get('categoryId');
				dispatch(fetchProductsFromCategory(Number(categoryId)));
				return;
			}
	
			if (searchParams.has('brandId')) {
				const brandId = searchParams.get('brandId');
				dispatch(fetchProductsFromBrand(Number(brandId)));
				return;
			}

			dispatch(fetchOneProduct(Number(productId)));
		}

	}, [dispatch, productId, products.length, searchParams]);

	const addToCartHandler = () => {
		if (!isAdded) {
			dispatch(addItemToCart(productItem!));
			setIsAdded(true);
		} else {
			dispatch(removeItemFromCart(productItem!.id));
			setIsAdded(false);
		}
	};

	const detailRef = useRef<HTMLDivElement>(null);
	const [clickedInside, setClickedInside] = useState(false);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				detailRef.current &&
				!detailRef.current.contains(event.target as Node)
			) {
				setClickedInside(false);
				setInfoType("default");
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [setInfoType]);

	const favoriteClassName = useMemo(
		() =>
			clsx(classes.favorite, {
				[classes.added]: isFavorite,
			}),
		[isFavorite]
	);

	const handleIsFavorite = useCallback(() => {
		if (!isFavorite) {
			const favoriteItem = products.find(item => item.id === Number(productId));
			if (favoriteItem) {
				dispatch(addItemToFavorite(favoriteItem));
				setIsFavorite(true);
			}
		} else {
			dispatch(removeItemFromFavorite(Number(productId)));
			setIsFavorite(false);
		}
	}, [dispatch, products, isFavorite, productId]);

	const { isMobile }: any = useMatchMedia();

	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		if (productsToShow && productItem && productsToShow.length > 0) {
			const productIndex = productsToShow.findIndex(
				item => item.productCode === productItem.productCode
			);
			setCurrentIndex(productIndex !== -1 ? productIndex : 0);
		}
	}, [productsToShow, productId, productItem]);

	const toUp = useCallback(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	const deliveryTextParts = [
		"доставка по Москве силами собственного курьера платформы man I trust / в пределах МКАД - бесплатно",
		"услуга выездной примерки по Москве в пределах МКАД - 1.700 руб / при условии приобретения товара - услуга бесплатна",
		"доставка по России и миру в настоящий момент недоступна / воспользуйтесь функцией <span class=\"underlinedLink\">уведомить</span>, чтобы первыми узнать об изменениях",
	]

	const payTextParts = [
		"наличный расчет — оплата наличными или переводом осуществляется курьеру, при получении / перед покупкой вы можете осмотреть, примерить и проверить товар в рамках услуги выездной примерки и, если товар вам не подходит, вы имеете право отказаться от его приобретения",
		"возможность оплаты безналичным расчетом на сайте или через терминал в настоящий момент недоступна / воспользуйтесь функцией <span class=\"underlinedLink\">уведомить</span>, чтобы первыми узнать об изменениях"
	]

	const backTextParts = [
		"на данном этапе, мы не принимаем возвраты после успешного совершения сделки, но предоставляем вам возможность принятия взвешенного решения о приобретении — услугой выездной примерки, отказом от покупки и временным ограничением доставки за пределами Москвы."
	]

	if (loading === "pending" || !productItem)
		return (
			<div className={classes.loader}>
				<Loader />
			</div>
		);

	return (
		<>
			<Animated className={classes.animated}>
				<div className={classes.wrapper}>
					<Swipers images={productItem?.images} />
					<div className={classes.info}>
						{infoType === "default" && (
							<motion.div
								className={classes.infos}
								variants={infoVariants}
								initial="hidden"
								animate="visible"
								exit="hidden">
									
								<Link className={classes.links} to="/authenticity">
									<Icon className={classes.agree} name="agree" size={50} />
								</Link>

								{!isNewCondition(productItem?.condition) && (
									<span className={classes.quality}>{productItem?.condition}</span>
								)}

								{isNewCondition(productItem?.condition) && availableSizes.size <=1 && (
									<span className={classes.quality}>{productItem?.size}</span>
								)}

								{availableSizes.size > 1 && (
									<div className={classes.sizes}>
										{[...availableSizes.keys()].map((size) => {
											const isCurrentlySelected = availableSizes.get(size)?.id === productItem.id;
											const isAvailable = !!!availableSizes.get(size)?.isSold;

											return (
												<div className={classes.all} key={size}>
													{isAvailable ? (
														<Link to={`/product/${availableSizes.get(size)?.id}`} onClick={toUp}>
															<span className={clsx(
																'hoverSpan',
																classes.detail, {
																	[classes.selected]: isCurrentlySelected,
																	[classes.unavailable]: false,
																}
															)}>
																{size}
															</span>
														</Link>
													) : (
														<Link to={`/product/${availableSizes.get(size)?.id}`} onClick={toUp}>
															<span className={clsx(
																'hoverSpan',
																classes.detail, {
																	[classes.selected]: isCurrentlySelected,
																	[classes.unavailable]: true,
																}
															)}>
																{size}
															</span>
														</Link>
													)}
													<span className={classes.line}>/</span>
												</div>
											);
										})}
									</div>
								)}

								<span className={classes.name}>
									<span>{productItem?.brand.name}</span> {productItem?.title}
								</span>
								<span className={classes.priceContainer}>
									{!isNotInStock  && (
										<>
											<Price
												price={productItem.price}
												discountPercent={discountPercent} />
										</>
									)}

									{isNotInStock && (
										<span>нет в наличии</span>
									)}
								</span>
								<div className={classes.buttons}>
									{!isNotInStock && (
										<>
											<button onClick={addToCartHandler} className={classes.cart}>
												{!isAdded
													? isMobile
														? "в корзину"
														: "добавить в корзину"
													: isMobile
													? "добавлено"
													: "добавлено в корзину"}
											</button>
										
											<button
												onClick={() => handleIsFavorite()}
												className={favoriteClassName}>
												вишлист{" "}
												<Icon className={classes.icon} name="heart" size={24} />
											</button>
										</>
									)}

									{isNotInStock && (
										<>
											<button
												onClick={() => setShowNotifyDialog(true)}
												className={clsx(
													classes.cart,
													classes.notify
												)}>
													уведомить
											</button>
											<button
												className={favoriteClassName}>
												вишлист
												<Icon className={classes.icon} name="heart" size={24} />
											</button>
										</>
									)}
								</div>{" "}
							</motion.div>
						)}
						{!isMobile ? (
							<div ref={detailRef} className={classes.more}>
								<More
									id={productId}
									productItem={productItem}
									type={infoType}
									setType={setInfoType}
								/>
								<div className={classes.things}>
									{details.map(({ id, text, type, description }) => (
										<div className={classes.all} key={text}>
											<button
												className={classes.detail}
												onClick={() => setInfoType(type)}
											>
												{text}
											</button>{" "}
											<span className={classes.line}>/</span>
										</div>
									))}{" "}
								</div>
								<div className={classes.id}>ID {productItem.productCode}</div>
							</div>
						) : (
							<div className={classes.accordions}>
								<Accordion title="детали" isOpen={true} className={classes.acc}>
									<ul>
										{productItem?.packing && (
											<li className={classes.list}>
												<div className={classes.title}>комплект: </div>
												<span>{productItem.packing}</span>
											</li>
										)}
										{productItem?.color && (
											<li className={classes.list}>
												<div className={classes.title}>цвет: </div>
												<span>{productItem?.color}</span>
											</li>
										)}
										{productItem?.material && (
											<li className={classes.list}>
												<div className={classes.title}>материал: </div>
												<span>{productItem?.material}</span>
											</li>
										)}
										{productItem?.accessories && (
											<li className={classes.list}>
												<div className={classes.title}>фурнитура: </div>
												<span>{productItem?.accessories}</span>
											</li>
										)}
										{productItem?.gender && (
											<li className={classes.list}>
												<div className={classes.title}>пол: </div>
												<span>{productItem?.gender}</span>
											</li>
										)}
										{productItem?.size && (
											<li className={classes.list}>
												<div className={classes.title}>размер: </div>
												<span>{productItem?.size}</span>
											</li>
										)}
										{productItem?.detailedCondition && (
											<li className={classes.list}>
												<div className={classes.title}>состояние: </div>
												<span>{productItem?.detailedCondition}</span>
											</li>
										)}
										{productItem?.purchaseYear && (
											<li className={classes.list}>
												<div className={classes.title}>
													год покупки: {" "}
												</div>
												<span>
													{productItem?.purchaseYear}
												</span>
											</li>
										)}{" "}
										<span className={classes.id}>ID {productItem.productCode}</span>
									</ul>
								</Accordion>
								<Accordion className={classes.acc} title="доставка">
									<div className={classes.description}>
										{deliveryTextParts.map(part => (
											<span dangerouslySetInnerHTML={{
												__html: part
											}}></span>
										))}
									</div>
									<Link className={classes.link} to="/delivery">
										подробнее
									</Link>
								</Accordion>
								<Accordion className={classes.acc} title="оплата">
									<div className={classes.description}>
										{payTextParts.map(part => (
											<span dangerouslySetInnerHTML={{
												__html: part
											}}></span>
										))}
									</div>
									<Link className={classes.link} to="/payment">
										подробнее
									</Link>
								</Accordion>
								<Accordion className={classes.acc} title="возрат">
									<div className={classes.description}>
										{backTextParts.map(part => (
											<span dangerouslySetInnerHTML={{
												__html: part
											}}></span>
										))}
									</div>
									<Link className={classes.link} to="/back">
										подробнее
									</Link>
								</Accordion>

								<div className={classes.banner}>
									<span>
										товар является оригинальным. man I trust несет полную финансовую ответственность за все товары, приобретенные у нас
									</span>
									<div className={classes.more}>
										<Link className={classes.link} to="/authenticity">
											подробнее
										</Link>
										<Icon className={classes.legit} name="agree" size={50} />
									</div>
								</div>
							</div>
						)}{" "}
					</div>
				</div>
				{/* {productsToShow.length > 1 && ( */}
					<div className={classes.footer}>
						{productsToShow.length > 1 && currentIndex > 0 && (
							<div className={classes.prev}>
								{" "}
								<Icon className={classes.icon} name="arrow" size={20} />
								
									<Link to={`/product/${productsToShow[currentIndex - 1].id}`} onClick={toUp}>
										{productsToShow[currentIndex - 1]?.title}
									</Link>
							</div>
						)}

						{productsToShow.length > 1 && currentIndex < productsToShow.length - 1 && (
							<div className={classes.next}>
									<Link to={`/product/${productsToShow[currentIndex + 1].id}`} onClick={toUp}>
										{productsToShow[currentIndex + 1]?.title}
									</Link>
								<Icon name="arrow" className={classes.icon} size={20} />
							</div>
						)}
					</div>
				{/* )} */}
			</Animated>

			{showNotifyDialog && (
				<NotifyDialog
					productId={productItem.id}
					productCode={productItem.productCode}
					onClose={handleNotify}
				/>
			)}
		</>
	);
};
