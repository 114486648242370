import { RootState } from "../store";
// import { isNewCondition } from "./helpers"; 

export const selectProductState = (state: RootState) => state.product;

export const selectProductsToShow = (state: RootState) => {
    //TODO: return this filtration logic when
    // const condition = state.product.conditionFilter;

    // if (condition === 'new') {
    //     return state.product.productsToShow.filter((p) => isNewCondition(p.condition));
    // }

    // return state.product.productsToShow.filter((p) => !isNewCondition(p.condition));
    return state.product.productsToShow;
}
