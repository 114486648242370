import type { FC, ReactNode } from "react";
import classes from "./Empty.module.scss";
import { Link } from "react-router-dom";
import clsx from "clsx";

export interface EmptyProps {
	children: ReactNode;
	className?: string;
}

export interface EmptyComponent extends FC<EmptyProps> {}

export const Empty: EmptyComponent = ({ children, className }) => {
	const wrppaerClassName = clsx(classes.wrapper, {}, className, [className]);

	return (
		<div className={wrppaerClassName}>
			<div className={classes.content}>
				<Link to="/" className={classes.button}>
					в магазин
				</Link>
				<span className={classes.text}>{children}</span>
			</div>
		</div>
	);
};
