import type { FC, ReactNode } from "react";
import { motion } from "framer-motion";

export interface AnimatedProps {
	children: ReactNode;
	className?: string;
}
export interface AnimatedComponent extends FC<AnimatedProps> {}

export const AnimatedMain: AnimatedComponent = ({ children, className }) => {
	const animation = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		exit: { opacity: 0 },
	};

	return (
		<motion.div
			variants={animation}
			initial="initial"
			animate="animate"
			exit="exit"
			transition={{ duration: 0.5 }}
			className={className}
		>
			{children}
		</motion.div>
	);
};
