import type { FC } from "react";
import classes from "./Cooperation.module.scss";
import { Animated } from "../../shared/components/Animated/Animated";

export const Cooperation: FC = () => {
	return (
		<Animated className={classes.wrapper}>
				<div className={classes.content}>
					<h1 className={classes.title}>сотрудничество</h1>
				</div>
				<div className={classes.text}>
					<span className={classes.first}>
						man I trust амбициозен и легок на подъем, потому открыт к сотрудничеству на всех фронтах.
					</span>
					<span className={classes.second}>
					если вы талантливы в том, что делаете и в том, что хотите нам предложить со взаимовыгодной целью или обладаете идеями и желаете расти вместе с нами, свяжитесь по контактам, указанным ниже. мы рассмотрим каждое предложение.
					</span>
				</div>
				<div className={classes.footer}>
					<a href="mailto:hi@manitrust.ru">hi@manitrust.ru </a>
					<a href="tel:8 (969) 112-20-20">8 (969) 112-20-20</a>
				</div>
		</Animated>
	);
};
