import { useMemo, useState } from "react";
import { motion } from "framer-motion";
import { FC, ReactNode } from "react";
import classes from "./Accordion.module.scss";
import clsx from "clsx";

export interface AccordionProps {
	title: string;
	children: ReactNode;
	className?: string;
	isOpen?: boolean;
}

export interface AccordionComponent extends FC<AccordionProps> {}

export const Accordion: AccordionComponent = ({
	title,
	children,
	className,
	isOpen = false,
}) => {
	const [isActive, setIsActive] = useState(isOpen);

	const accordionHandler = () => {
		setIsActive(!isActive);
	};

	const wrapperClassName = useMemo(
		() => clsx(classes.wrapper, { [classes.active]: isActive }, className),
		[className, isActive]
	);

	return (
		<div className={wrapperClassName}>
			<button className={classes.title} onClick={accordionHandler}>
				{title}
				{isActive ? (
					<button className={classes.cross} />
				) : (
					<button className={classes.plus} />
				)}
			</button>
			<motion.div
				className={classes.content}
				initial="collapsed"
				animate={isActive ? "expanded" : "collapsed"}
				variants={{
					expanded: { opacity: 1, height: "auto" },
					collapsed: {
						opacity: 0,
						height: 0,
					},
				}}
				transition={{ duration: 0.3 }}
			>
				{children}
			</motion.div>
		</div>
	);
};
