import { useMatchMedia } from "../../shared/hooks/useMatchMedia";
import { Dialog } from "../Dialog/Dialog";
import classes from './CookiesDialog.module.scss';

type CookiesDialogProps = {
    onClose: () => void,
}
export const CookiesDialog = (props: CookiesDialogProps) => {
    const { isMobile }: any = useMatchMedia();

    const { onClose } = props;

    const top = isMobile ? "60dvh" : "calc(50dvh - 251px)";
    const left = isMobile ? "calc(50vw - 177px)" : "calc(50vw - 203px)";

    return (
        <Dialog top={top} left={left}>
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <p>мы используем файлы cookies для улучшения качества сервиса и вашего опыта пользованием нашим сайтом</p>
                    {!isMobile && (<hr />)}
                    <p className={classes.consent}>продолжая, вы соглашаетесь с нашей политикой обработки данных</p>
                </div>

                <div className={classes.buttonContainer}>
                    <button onClick={() => onClose()} className={classes.button}>
                        окей
                    </button>
                </div>
            </div>
        </Dialog>
    )
}