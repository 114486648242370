import type { FC, MouseEvent } from "react";
import classes from "./Filter.module.scss";
import { Link } from "react-router-dom";
import { MotionValue, motion } from "framer-motion";
import { useMatchMedia } from "../../hooks/useMatchMedia";
import clsx from "clsx";

export interface FilterProps {
	title: string;
	link: string;
	status: string;
	index: number;
	image?: string;

	handleMouseLeave: (
		e: MouseEvent,
		image: string | undefined,
		index: number
	) => void;

	handleMouseInteraction: (
		image: string | undefined,
		index: number,
		isEntering: boolean,
		e: React.MouseEvent
	) => void;

	x: MotionValue<number>;
	y: MotionValue<number>;
	hoveredImage: string | undefined;
	hoveredIndex: number | undefined;
}

export interface FilterComponent extends FC<FilterProps> {}

export const Filter: FilterComponent = ({
	title,
	status,
	image,
	link,
	index,
	handleMouseInteraction,
	handleMouseLeave,
	hoveredImage,
	hoveredIndex,
	x,
	y,
}) => {
	const { isMobile }: any = useMatchMedia();
	return (
		<li
			className={classes.category}
			style={{ zIndex: hoveredIndex === index ? 2 : 1 }}
		>
			<Link className={classes.link} to={link}>
				<h2
					className={
						status === "category"
							? classes.title
							: status === "sale"
							? classes.sale
							: classes.brand
					}
					onMouseEnter={
						!isMobile
							? e => handleMouseInteraction(image, index, true, e)
							: undefined
					}
					onMouseLeave={
						!isMobile ? e => handleMouseLeave(e, image, index) : undefined
					}
				>
					{title}
				</h2>
				{!isMobile && hoveredImage && hoveredIndex === index && (
					<motion.img
						className={classes.image}
						src={hoveredImage}
						alt=""
						style={{
							left: x,
							top: y,
							transform: `translate(${x}, ${y})`,
							pointerEvents: "none",
						}}
					/>
				)}{" "}
			</Link>
			<span
				className={clsx(
					classes.slash, {
						[classes.hideSlash]: !!hoveredImage && image === hoveredImage && hoveredIndex === index
					}
				)}
			>
				/
			</span>{" "}
		</li>
	);
};
