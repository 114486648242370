import { RootState } from "../store";

export const cartSelector = (state: RootState) => state.cart;

export const selectAddedItemsIds = (state: RootState) => {
    return state.cart.items.reduce((acc, cartItem) => {
        acc.push(cartItem.id);
        return acc;
    }, [] as number[]);
}

export const selectCartTotal = (state: RootState) => {
    let totalSum = 0;
    let totalDiscount = 0;
    let totalPromocodeDiscount = 0;
    let totalDelivery = 0;

    state.cart.items.forEach((item) => {
        const { price, discount } = item;
        totalSum += price;

        if (discount && !discount.promocode) {
            totalDiscount =  (discount.percentDiscount * price) / 100;
        }

        state.cart.appliedPromocodes.forEach((code) => {
            if (discount && discount.promocode === code) {
                totalPromocodeDiscount += (discount.percentDiscount * price) / 100;
    
                return true;
            }
        })

    });

    totalDelivery = state.cart.isFitting ? 1700 : 0;

    return {
        totalSum,
        totalDiscount,
        totalPromocodeDiscount,
        totalDelivery,
    }
}
