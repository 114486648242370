import { useState, type FC } from "react";
import { Categories } from "../../components/Categories/Categories";
import { AnimatedMain } from "../../shared/components/AnimatedMain/AnimatedMain";
import classes from './Home.module.scss';
import { RunningLine } from "../../components/RunningLine/RunningLine";
import { CookiesDialog } from "../../components/CookiesDialog/CookiesDialog";
// import { ConditionSelector } from "../../components/ConditionSelector/ConditionSelector";

const LS_COOKIES = `cookies_policy_accepted`;

export const Home: FC = () => {
	const [areCategoriesLoaded, setAreCategoriesLoaded] = useState(false);

	const shouldShow = !!!localStorage.getItem(LS_COOKIES);

	const [showCookiesDialog, setShowCookiesDialog] = useState(shouldShow)

	const handleCookiesAccept = () => {
		localStorage.setItem(LS_COOKIES, JSON.stringify(true));
		setShowCookiesDialog(false);
	}

	const handleCategoriesLoaded = () => {
		setAreCategoriesLoaded(true);
	}

	return (
		<AnimatedMain className={classes.wrapper}>
				<Categories onLoaded={handleCategoriesLoaded} />

				{areCategoriesLoaded && (
					<>
						<RunningLine />
						{/* <ConditionSelector/> */}
		
						{showCookiesDialog && (
							<CookiesDialog
								onClose={handleCookiesAccept}/>
						)}
					</>
				)}
		</AnimatedMain>
	);
};
