import clsx from "clsx";

import classes from "./Pagination.module.scss";

import { Icon } from "../../Icon/Icon";
interface PaginationProps {
	totalCount: number;
	pageSize: number;
	currentPage: number;
	onPageChange: (page: number) => void;
	className?: string;
}

export const Pagination: React.FC<PaginationProps> = ({
	totalCount,
	pageSize,
	currentPage,
	onPageChange,
	className,
}) => {
	if (totalCount <= pageSize) {
		return null;
	}

	const totalPageCount = Math.ceil(totalCount / pageSize);

	const onNext = () => {
		if (currentPage !== totalPageCount) {
			onPageChange(currentPage + 1);
			window.scrollTo({ top: 0 });
		}
	};

	const onPrevious = () => {
		if (currentPage !== 1) {
			onPageChange(currentPage - 1);
			window.scrollTo({ top: 0 });
		}
	};

	return (
		<ul
			className={clsx(classes.paginationContainer, {
				[classes[className || ""]]: className,
			})}
		>
			<li
				className={clsx(classes.paginationItem, {
					[classes.hidden]: currentPage === 1,
				})}
				onClick={onPrevious}>
				<button onClick={onPrevious}>
					<Icon className={classes.iconArrowLeft} name="arrow" />
				</button>
			</li>

			<li>
				{`${currentPage} / ${totalPageCount}`}
			</li>

			<li
				className={clsx(classes.paginationItem, {
					[classes.hidden]: currentPage === totalPageCount,
				})}
				onClick={onNext}>
				<button onClick={onNext}>
					<Icon name="arrow" />
				</button>
			</li>
		</ul>
	);
};
