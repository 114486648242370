import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FormState } from "./type";
import { postOrder } from "./thunk";

const initialState: FormState = {
	firstName: "",
	lastName: "",
	email: "",
	country: "",
	region: "",
	city: "",
	street: "",
	house: "",
	apartment: "",
	zipCode: "",
	phone: "",
	comment: "",
	productIds: [],

	deliveryType: "",
	paymentType: "",

	postStatus: "idle",
	postError: null,
};

export const formSlice = createSlice({
	name: "form",
	initialState,
	reducers: {
		setFormData: (state, action: PayloadAction<Partial<FormState>>) => {
			return { ...state, ...action.payload };
		},
		setDeliveryType: (state, action: PayloadAction<string>) => {
			state.deliveryType = action.payload;
		},
		setPaymentType: (state, action: PayloadAction<string>) => {
			state.paymentType = action.payload;
		},
		resetFormData: state => {
			return { ...state, ...initialState };
		},
	},

	extraReducers: builder => {
		builder
			.addCase(postOrder.pending, state => {
				state.postStatus = "loading";
			})
			.addCase(postOrder.fulfilled, state => {
				state.postStatus = "succeeded";
				state.postError = null;
			})
			.addCase(
				postOrder.rejected,
				(state, action: PayloadAction<{ message: string } | undefined>) => {
					state.postStatus = "failed";
					if (action.payload) {
						state.postError = action.payload.message;
					} else {
						state.postError = null;
					}
				}
			);
	},
});

export const { setFormData, resetFormData, setDeliveryType, setPaymentType } = formSlice.actions;

export default formSlice.reducer;
