import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUrl } from "../../services/get-url";

export const fetchBrand = createAsyncThunk(
	"brands/fetchBrand",
	async () => {
		const response = await axios.get(
			getUrl(`/api/brands/`)
		);
		return response.data;
	}
);
