import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FavoriteItem, FavoriteState } from "./type";
import { toggleProductFavorite } from "../productSlice/slice";

const LS_FAVORITE = "favorite";
const LS_ADDED = "added_it2";
const LS_FAVORITE_ITEMS = "items-fav2";

const InitialState: FavoriteState = {
	favorite: JSON.parse(localStorage.getItem(LS_FAVORITE) ?? "[]"),
	added: JSON.parse(localStorage.getItem(LS_ADDED) ?? "0"),
	favoriteItems: JSON.parse(localStorage.getItem(LS_FAVORITE_ITEMS) ?? "[]"),
};

const favoriteSlice = createSlice({
	name: "favorite",
	initialState: InitialState,
	reducers: {
		addItemToFavorite(state, action: PayloadAction<FavoriteItem>) {
			state.favorite.push(action.payload);
			state.added++;
			state.favoriteItems.push(action.payload.id);
			localStorage.setItem(LS_FAVORITE, JSON.stringify(state.favorite));
			localStorage.setItem(LS_ADDED, JSON.stringify(state.added));
			localStorage.setItem(
				LS_FAVORITE_ITEMS,
				JSON.stringify(state.favoriteItems)
			);
		},
		removeItemFromFavorite(state, action: PayloadAction<number>) {
			const index = state.favorite.findIndex(
				item => item.id === action.payload
			);
			if (index !== -1) {
				state.favorite.splice(index, 1);
				state.added--;
				const favoriteItemIndex = state.favoriteItems.indexOf(action.payload);
				if (favoriteItemIndex !== -1) {
					state.favoriteItems.splice(favoriteItemIndex, 1);
				}
				toggleProductFavorite(action.payload);
			}
			localStorage.setItem(LS_FAVORITE, JSON.stringify(state.favorite));
			localStorage.setItem(LS_ADDED, JSON.stringify(state.added));
			localStorage.setItem(
				LS_FAVORITE_ITEMS,
				JSON.stringify(state.favoriteItems)
			);
		},
	},
});
export const { addItemToFavorite, removeItemFromFavorite } =
	favoriteSlice.actions;
export default favoriteSlice.reducer;
