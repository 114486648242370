import { useMemo, type FC } from "react";
import classes from "./Questions.module.scss";
import { Accordion } from "../../shared/components/Accordion/Accordion";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

export interface QuestionsType {
	id: number;
	question: string;
	answer: string;
}

export interface QuestionsProps {
	className: string;
	index?: string;
}

export interface QuestionsComponent extends FC<QuestionsProps> {}

export const Questions: QuestionsComponent = ({ className, index }) => {
	const question1: QuestionsType[] = [
		{
			id: 1,
			question: "преимущества продажи на платформе man I trust?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},

		{
			id: 2,
			question: "какие варианты сотрудничества возможны для продавцов?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},

		{
			id: 3,
			question: "какие изделия вы принимаете? ",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 4,
			question: "размер комиссии man I trust в случае реализации?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 5,
			question: "кто определяет стоимость товара?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 6,
			question: "каким образом осуществляется продажа?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 7,
			question: "как передать изделие в man I trust?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 8,
			question: "как получить выплату за продажу?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
	];

	const question2: QuestionsType[] = [
		{
			id: 1,
			question: "преимущества покупаю на платформе man I trust?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},

		{
			id: 2,
			question: "какие варианты сотрудничества возможны для тех кто покупает?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},

		{
			id: 3,
			question: "какие изделия вы принимаете? ",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 4,
			question: "размер комиссии man I trust в случае реализации?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 5,
			question: "кто определяет стоимость товара?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 6,
			question: "каким образом осуществляется продажа?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 7,
			question: "как передать изделие в man I trust?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 8,
			question: "как получить выплату за продажу?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
	];

	const question3: QuestionsType[] = [
		{
			id: 1,
			question: "как проходит регистрация?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},

		{
			id: 2,
			question: "какие варианты сотрудничества возможны для продавцов?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},

		{
			id: 3,
			question: "какие изделия вы принимаете? ",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 4,
			question: "размер комиссии man I trust в случае реализации?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 5,
			question: "кто определяет стоимость товара?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 6,
			question: "каким образом осуществляется продажа?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 7,
			question: "как передать изделие в man I trust?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 8,
			question: "как получить выплату за продажу?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
	];

	const question4: QuestionsType[] = [
		{
			id: 1,
			question: "преимущества покупаю на платформе man I trust?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},

		{
			id: 2,
			question: "какие варианты сотрудничества возможны для продавцов?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},

		{
			id: 3,
			question: "какие изделия вы принимаете? ",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 4,
			question: "размер комиссии man I trust в случае реализации?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 5,
			question: "кто определяет стоимость товара?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 6,
			question: "каким образом осуществляется продажа?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 7,
			question: "как передать изделие в man I trust?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
		{
			id: 8,
			question: "как получить выплату за продажу?",
			answer:
				"Стоимость изделия при оценке формируется из таких факторов как:актуальность модели, цвет изделия, состояние, комплект и год выпуска. Благодаря богатому опыту наши менеджеры определяют оптимальную стоимость для выкупа и реализации.",
		},
	];

	const wrapperClassName = useMemo(
		() => clsx(classes.wrapper, {}, className),
		[className]
	);

	return (
		<div className={wrapperClassName}>
			{index === "first" && (
				<div className={classes.content}>
					{question1.map(({ question, answer }) => (
						<Accordion key={uuidv4()} title={question}>
							{answer}
						</Accordion>
					))}
				</div>
			)}
			{index === "second" && (
				<div className={classes.content}>
					{question2.map(({ question, answer }) => (
						<Accordion key={uuidv4()} title={question}>
							{answer}
						</Accordion>
					))}
				</div>
			)}
			{index === "third" && (
				<div className={classes.content}>
					{question3.map(({ question, answer }) => (
						<Accordion key={uuidv4()} title={question}>
							{answer}
						</Accordion>
					))}
				</div>
			)}
			{index === "fourth" && (
				<div className={classes.content}>
					{question4.map(({ question, answer }) => (
						<Accordion key={uuidv4()} title={question}>
							{answer}
						</Accordion>
					))}
				</div>
			)}
		</div>
	);
};
