import { FC, ReactNode, useMemo, useState } from "react";
import classes from "./Tooltip.module.scss";
import clsx from "clsx";

export interface TooltipProps {
	children: ReactNode;
	tooltip: string;
	className?: string;
}

export const Tooltip: FC<TooltipProps> = ({ children, tooltip, className }) => {
	const [isHovered, setIsHovered] = useState(false);

	const wrapperClassName = useMemo(
		() => clsx(classes.wrapper, className),
		[className]
	);

	return (
		<button
			className={wrapperClassName}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{children}
			{isHovered && <div className={classes.text}>{tooltip}</div>}
		</button>
	);
};
