import type { FC } from "react";
import classes from "./Authenticity.module.scss";
import { Icon } from "../../shared/Icon/Icon";
import { Animated } from "../../shared/components/Animated/Animated";

export const Authenticity: FC = () => {
	return (
		<Animated>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<h1 className={classes.title}>гарантия подлинности</h1>
					<Icon className={classes.icon} name="agree" />
				</div>
				<div className={classes.text}>
					<span className={classes.first}>
						man I trust является качественным связующим звеном официального партнера модных домов в Европе и людьми в России, желающими иметь возможность приобретать продукты брендов привычным образом в сопровождении сервиса должного уровня.
					</span>
					<span className={classes.second}>
						за время жизни проекта, в этом вопросе мы не подвели ни одного человека, решившим стать нашим клиентом и продолжаем заверять вас в подлинности нашего ассортимента. мы благодарны и ценим доверие наших клиентов, сопровождающее нас с самого начала пути и зовем это нашим главным активом. 
					</span>
					<span className={classes.third}>
						на данном этапе мы работаем онлайн, предоставляя услугу выездной примерки, позволяющую увидеть товар вживую и принять взвешенное и обдуманное решение, подходит он вам или нет, до момента покупки. man I trust несет полную финансовую ответственность за все товары, приобретенные у нас. 
					</span>
				</div>
				<br />
				<div className={classes.footer}>c уважением, команда man i trust</div>
			</div>
		</Animated>
	);
};
