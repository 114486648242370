import { FC, useMemo, useRef } from "react";
import classes from "./Navlink.module.scss";
import clsx from "clsx";

interface NavlinkProps {
	active: boolean;
	onClick: (dimensions: DOMRect) => void;
	text: string;
	className?: string;
}

export interface NavlinkComponent extends FC<NavlinkProps> {}

export const Navlink: NavlinkComponent = ({
	active,
	onClick,
	text,
	className,
}) => {
	const linkRef = useRef<HTMLDivElement>(null);

	const handleClick = () => {
		const dimensions = linkRef.current?.getBoundingClientRect();
		if (dimensions) {
			onClick(dimensions);
		}
	};

	const wrapperClassName = useMemo(
		() => clsx(classes.wrapper, {}, className),
		[className]
	);

	return (
		<div ref={linkRef} className={wrapperClassName} onClick={handleClick}>
			<button className={`${classes.link} ${active ? classes.active : ""}`}>
				{text}
			</button>
		</div>
	);
};
