import classes from "./Loader.module.scss";

export const Loader = () => {
	return (
		<svg viewBox="0 0 500 500" className={classes.wrapper}>
			<path
				id="circlePath"
				fill="transparent"
				stroke="rgba(0, 0, 0, 0.1)"
				strokeDasharray="1000"
				strokeDashoffset="100"
				d="M 250, 250 m -200, 0 a 200,200 0 1,0 400,0 a 200,200 0 1,1 -400,0"
				className={classes.circle}
			/>
		</svg>
	);
};
