import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BrandModel, BrandState } from "./type";
import { fetchBrand } from "./thunk";

const initialState: BrandState = {
	brand: [],
	loading: "pending",
};

export const brandSlice = createSlice({
	name: "brand",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchBrand.pending, state => {
				state.loading = "pending";
				state.brand = [];
			})
			.addCase(
				fetchBrand.fulfilled,
				(state, action: PayloadAction<BrandModel[]>) => {
					state.loading = "success";
					state.brand = action.payload;
				}
			)
			.addCase(fetchBrand.rejected, state => {
				state.loading = "error";
				state.brand = [];
			});
	},
});

export default brandSlice.reducer;
