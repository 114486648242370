import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUrl } from "../../services/get-url";

export const fetchCategory = createAsyncThunk(
	"categories/fetchCategory",
	async () => {
		const response = await axios.get(
			getUrl(`/api/categories/`)
		);
		return response.data;
	}
);
