import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CategoryModel, CategoryState  } from "./type";
import { fetchCategory } from "./thunk";

const LS_BRANDNAME = "brand";

const initialState: CategoryState = {
	category: [],
	loading: "pending",
	brandName: "",
};

export const ALL_CATEGORY_ID = -2;

const allCategory = {
	id: ALL_CATEGORY_ID,
	name: 'все',
	thumbnailPath: null,
}

export const categorySlice = createSlice({
	name: "category",
	initialState,
	reducers: {
		setBrandName: (state, action) => {
			state.brandName = action.payload;
			localStorage.setItem(LS_BRANDNAME, JSON.stringify(state.brandName));
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchCategory.pending, state => {
				state.loading = "pending";
				state.category = [];
			})
			.addCase(
				fetchCategory.fulfilled,
				(state, action: PayloadAction<CategoryModel[]>) => {
					state.loading = "success";

					const allCategories = [...action.payload];

					allCategories.splice(1, 0, allCategory);
					state.category = allCategories;
				}
			)
			.addCase(fetchCategory.rejected, state => {
				state.loading = "error";
				state.category = [];
			});
	},
});
export const { setBrandName } = categorySlice.actions;
export default categorySlice.reducer;
