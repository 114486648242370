import { ReactComponent as cart } from "./cart.svg";
import { ReactComponent as heart } from "./heart.svg";
import { ReactComponent as logo } from "./logo.svg";
import { ReactComponent as agree } from "./agree.svg";
import { ReactComponent as arrow } from "./arrow.svg";
import { ReactComponent as ruble } from "./ruble.svg";
import { ReactComponent as allow } from "./allow.svg";
import { ReactComponent as phone } from "./phone.svg";
import { ReactComponent as circle } from "./circle.svg";
import { ReactComponent as circleLong } from "./circleLong.svg";

export const ICONS = {
	cart,
	heart,
	logo,
	agree,
	arrow,
	ruble,
	allow,
	phone,
	circle,
	circleLong,
} as const;

export type IconNames = keyof typeof ICONS;
