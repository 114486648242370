import {
	useCallback,
	type FC,
	type MouseEventHandler,
	useMemo,
	useState,
} from "react";
import classes from "./CartItem.module.scss";
import { Icon } from "../../Icon/Icon";
import { useMatchMedia } from "../../hooks/useMatchMedia";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { favoriteSelector } from "../../../store/favoriteSlice/selector";
import { selectProductState } from "../../../store/productSlice/selector";
import {
	addItemToFavorite,
	removeItemFromFavorite,
} from "../../../store/favoriteSlice/slice";
import clsx from "clsx";
import { removeItemFromCart } from "../../../store/cartSlice/slice";
import { Link } from "react-router-dom";
import { formatPrice } from "../../../services/format-price";
import { DiscountType } from "../../../store/productSlice/type";

export interface CartItemProps {
	id: number;
	productCode: string;
	productCategory?: string,
	title: string;
	image: string;
	price: number;
	state: string;
	productType: string;
	size: string;
	brand?: string;
	discount?: DiscountType,
	onClick: MouseEventHandler<HTMLButtonElement> | undefined;
}

export interface CatItemComponent extends FC<CartItemProps> {}

export const CartItem: CatItemComponent = ({
	id,
	productCode,
	discount,
	title,
	image,
	price,
	state,
	productType,
	size,
	onClick,
	brand,
}) => {
	const { isMobile }: any = useMatchMedia();
	const dispatch = useAppDispatch();

	const { favoriteItems } = useAppSelector(favoriteSelector);
	const { products } = useAppSelector(selectProductState);

	const isItemInFavorite = useMemo(
		() => favoriteItems.includes(id),
		[favoriteItems, id]
	);
	const [isFavorite, setIsFavorite] = useState(isItemInFavorite);

	const favoriteClassName = clsx(classes.icon, {
		[classes.favorite]: isFavorite,
	});

	const hasDiscount = discount && !discount.promocode;

	const handleIsFavorite = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
		event.preventDefault();
		event.stopPropagation();

		if (!isFavorite) {
			const favoriteItem = products.find(item => item.id === id);
			if (favoriteItem) {
				dispatch(addItemToFavorite(favoriteItem));
				dispatch(removeItemFromCart(id));
				setIsFavorite(true);
			}
		} else {
			dispatch(removeItemFromFavorite(id));
			setIsFavorite(false);
		}
	}, [dispatch, products, isFavorite, id]);

	const handleCloseClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
		event.preventDefault();
		event.stopPropagation();

		onClick?.(event);
	}, [onClick]);

	return (
		<>
			{!isMobile ? (
				<div className={classes.cart} key={id}>
					<Link to={`/product/${id}`}>
						<div className={clsx(
							classes.content,
							'hoverDiv'
						)}>
							<img className={classes.image} src={image} alt="cart" />
							<div className={classes.info}>
								<span className={classes.quality}>{state}</span>
								<div className={classes.name}>
									<span>{brand}</span>&nbsp;
									<span className={classes.title}>{title}</span>
								</div>
								<div className={classes.packing}>{productType}</div>
								<div className={classes.id}>ID {productCode}</div>
								<div className={classes.total}>1 шт.</div>
							</div>
							<div className={classes.price}>
								<span>стоимость</span>
								<span className={clsx(
									classes.alls, {
										[classes.striked]: hasDiscount
									}
								)}>
									<Icon className={classes.ruble} name="ruble" size={12} />
									{formatPrice(price)}
								</span>
								{hasDiscount && (
									<>
										<span className={clsx(
											classes.sale,
										)}>
											{`-${discount.percentDiscount}%`}
										</span>
				
										<span className={clsx(
											classes.alls,
											// classes.sale
										)}>
											<Icon className={classes.ruble} name="ruble" size={12} />
											{formatPrice(Math.floor(price * (100 - discount.percentDiscount) / 100))}
										</span>
									</>
								)}
							</div>
							<div className={classes.size}>
								<span>размер</span>
								{size}
								<div className={classes.favorite} onClick={handleIsFavorite}>
									<Icon className={favoriteClassName} size={18} name="heart" />
									<span>в вишлист</span>
								</div>
							</div>
							<div className={classes.buttonWrapper}>
								<button className={classes.button} onClick={handleCloseClick} />
							</div>
						</div>
					</Link>
				</div>
			) : (
				<Link to={`/product/${id}`}>
					<div className={classes.mobile} key={id}>
							<div className={classes.infoWrapper}>
								<img className={classes.image} src={image} alt="cart" />
								<div className={classes.content}>

									<div className={classes.info}>
										<div className={classes.nameWrapper}>
											<span className={classes.quality}>{state}</span>

											<div className={classes.name}>
												<span>{brand}</span>&nbsp;{title}
											</div>
											<div className={classes.packing}>{productType}</div>
										</div>

										<div className={classes.id}>ID {productCode}</div>
										
										<div className={classes.size}>
											<span>размер</span>
											{size}
										</div>
									</div>

									<div className={classes.favorite} onClick={handleIsFavorite}>
										<Icon className={favoriteClassName} name="heart" />
										<span>в вишлист</span>
									</div>
								</div>
								<div className={classes.end}>
									{" "}
									<button className={classes.button} onClick={handleCloseClick} />
									<div className={classes.total}>1 шт.</div>
									<div className={classes.price}>
										<span>стоимость</span>
									</div>
								</div>
							</div>
							
							<div className={classes.res}>
								<div className={classes.priceWrapper}>
									<Icon name="ruble" size={9} className={classes.ruble} />
									&nbsp;
									<span className={clsx({
										[classes.striked]: hasDiscount,
									})}>
										{formatPrice(price)}
									</span>
								</div>

								{hasDiscount && (
									<>
										<div>
											<span className={clsx(
												classes.sale,
											)}>
												{`-${discount.percentDiscount}%`}
											</span>
										</div>

										<div className={classes.priceWrapper}>
											<Icon name="ruble" size={9} className={classes.ruble} />
											&nbsp;
											{formatPrice(Math.floor(price * (100 - discount.percentDiscount) / 100))}
										</div>
									</>
								)}
							</div>
					</div>
				</Link>
			)}
		</>
	);
};
