import type { FC } from "react";
import classes from "./About.module.scss";
import { Animated } from "../../shared/components/Animated/Animated";

export const About: FC = () => {
	return (
		<Animated>
			<div className={classes.wrapper}>
				<img className={classes.image} src="images/shoes.png" alt="" />
				<div className={classes.right}>
					<div className={classes.contacts}>
						<h1 className={classes.title}>контакты</h1>
						<div className={classes.data}>
							email: <a href="mailto:hi@manitrust.ru">hi@manitrust.ru</a>
						</div>
						<div className={classes.data}>
							instagram:{" "}
							<a
								href="https://www.instagram.com/manitrust.ru/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
								target="_blank"
								rel="noreferrer"
							>
								manitrust.ru
							</a>
						</div>
						<div className={classes.data}>
							telegram:{" "}
							<a href="http://t.me/manitrust" target="_blank">
								t.me/manitrust
							</a>
						</div>
						<div className={classes.data}>
							whatsapp:{" "}
							<a href="wa.me/79691122020" target="_blank" rel="noreferrer">
								wa.me/79691122020
							</a>
						</div>
						<div className={classes.data}>
							телефон:{" "}
							<a href="tel:89691122020" target="_blank" rel="noreferrer">
								8 (969) 112-20-20
							</a>
						</div>
					</div>
					<div className={classes.about}>
						<h1 className={classes.title}>о нас</h1>
						<span className={classes.text}>
							man I trust существует из любви к моде и самовыражению. сосредоточившись на исключительном сервисе, безупречной селекции и внимании к деталям, мы сочетаем в себе вневременную перспективу с тонким отношением, которое формирует наш почерк. человек, которому мы доверяем, в первую очередь, есть торговец эмоциями.
						</span>
					</div>
				</div>
			</div>
		</Animated>
	);
};
