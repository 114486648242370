import { Route, Routes } from "react-router-dom";

import { Favorites } from "../Favorites/Favorites";
import { Authenticity } from "../Authenticity/Authenticity";
import { About } from "../About/About";
import { Cart } from "../Cart/Cart";
import { Sell } from "../Sell/Sell";
import { IndividualOrder } from "../Order/Order";
import { Footer } from "../../components/Footer/Footer";
import { Question } from "../Quesiton/Question";
import { Delivery } from "../Delivery/Delivery";
import { Cooperation } from "../Cooperation/Cooperation";
import { Thankfull } from "../Thankfull/Thankfull";
import { Product } from "../Product/Product";
import { Pay } from "../Pay/Pay";
import { Back } from "../Back/Back";
import classes from "./Layout.module.scss";
import { ProductList } from "../ProductList/ProductList";
import { UserAgreement } from "../UserAgreement/UserAgreement";
import { PrivacyPolicy } from "../PrivacyPolicy/PrivacyPolicy";

export function Layout() {
	return (
		<div className={classes.wrapper}>
			<div className={classes.content}>
				<Routes>
					<Route path="/category/:id" element={<ProductList loadByCategory />} />
					<Route path="/brand/:id" element={<ProductList loadByBrand />} />
					<Route path="/sale" element={<ProductList loadDiscounted />} />
					<Route path="/all" element={<ProductList loadAll />} />
					<Route path="/favorites" element={<Favorites />} />
					<Route path="/authenticity" element={<Authenticity />} />
					<Route path="/user-agreement" element={<UserAgreement />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/about-us" element={<About />} />
					<Route path="/cart" element={<Cart />} />
					<Route path="/sell" element={<Sell />} />
					<Route path="/individual-order" element={<IndividualOrder />} />
					<Route path="/questions" element={<Question />} />
					<Route path="/delivery" element={<Delivery activeLink="delivery" />} />
					<Route path="/payment" element={<Delivery activeLink="payment" />} />
					<Route path="/cooperation" element={<Cooperation />} />
					<Route path="/thankfull" element={<Thankfull />} />
					<Route path="/product/:productId" element={<Product />} />
					<Route path="/pay" element={<Pay />} />
					<Route path="/back" element={<Back />} />
				</Routes>
			</div>
			<Footer />
		</div>
	);
}
