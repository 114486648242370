import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItem, CartState } from "./type";

const LS_CART = "cart";

const InitialState: CartState = {
	items: JSON.parse(localStorage.getItem(LS_CART) ?? "[]"),
	isFitting: false,
	appliedPromocodes: [],
};

const cartSlice = createSlice({
	name: "cart",
	initialState: InitialState,
	reducers: {
		addItemToCart(state, action: PayloadAction<CartItem>) {
			state.items.push(action.payload);

			localStorage.setItem(LS_CART, JSON.stringify(state.items));
		},
		removeItemFromCart(state, action: PayloadAction<number>) {
			const index = state.items.findIndex(item => item.id === action.payload);

			if (index !== -1) {
				state.items.splice(index, 1);
			}

			localStorage.setItem(LS_CART, JSON.stringify(state.items));
		},
		clearCart(state) {
			state.items = [];
			state.appliedPromocodes = [];
			state.isFitting = false;
			localStorage.removeItem(LS_CART);
		},
		setFitting: (state, action: PayloadAction<boolean>) => {
			state.isFitting = action.payload;
		},
		checkAndAddPromocode: (state, action: PayloadAction<string>) => {
			const code = action.payload;

			state.items.some((cartItem) => {
				if (cartItem.discount?.promocode === code && !state.appliedPromocodes.includes(code)) {

					state.appliedPromocodes.push(code);

					return true;
				}

				return false;
			})
		}
	},
});

export const {
	addItemToCart,
	removeItemFromCart,
	clearCart,
	setFitting,
	checkAndAddPromocode
} = cartSlice.actions;
export default cartSlice.reducer;
