import { FC, useCallback, useEffect, useRef, useState } from "react";
import classes from "./Categories.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useMotionValue } from "framer-motion";
import { Filter } from "../../shared/components/Filter/Filter";
import { useAppDispatch } from "../../shared/hooks/useAppDispatch";
import { useAppSelector } from "../../shared/hooks/useAppSelector";
import { categorySelector } from "../../store/categorySlice/selector";
import { fetchCategory } from "../../store/categorySlice/thunk";
import { Loader } from "../../shared/components/Loader/Loader";
import { fetchBrand } from "../../store/brandSlice/thunk";
import { brandSelector } from "../../store/brandSlice/selector";
import { getImagePath } from "../../services/image-path";
import { ALL_CATEGORY_ID } from "../../store/categorySlice/slice";

export interface CategoriesType {
	title: string;
	status: string;
	image: string;
	link: string;
}

type CategoriesProps = {
	onLoaded: () => void,
}

export const Categories = ({ onLoaded }: CategoriesProps) => {
	const dispatch = useAppDispatch();
	const { category, loading: categoryLoading } = useAppSelector(categorySelector);
	const { brand, loading: brandLoading } = useAppSelector(brandSelector);

	useEffect(() => {
		dispatch(fetchCategory());
		dispatch(fetchBrand());
	}, [dispatch]);

	const constraintsRef = useRef(null);
	const [hoveredImage, setHoveredImage] = useState<string | undefined>(
		undefined
	);
	const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(
		undefined
	);

	const x = useMotionValue(0);
	const y = useMotionValue(0);

	const handleMouseInteraction = useCallback((
		image: string | undefined,
		index: number,
		isEntering: boolean,
	) => {
		setHoveredImage(isEntering ? image : undefined);
		setHoveredIndex(isEntering ? index : undefined);
	}, []);

	const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
		x.set(e.clientX - 250);
		y.set(e.clientY - 290);
	}, [x, y]);

	const handleMouseLeave = (
		e: React.MouseEvent,
		image: string | undefined,
		index: number
	) => {
		if (hoveredIndex === index) {
			handleMouseInteraction(image, index, false);
		}
	};

	useEffect(() => {
		if (categoryLoading === 'success' && brandLoading === 'success') {
			onLoaded();
		}
	}, [brandLoading, categoryLoading, onLoaded]);

	if (categoryLoading !== "success" && brandLoading !== "success") return <Loader />;

	return (
		<div
			className={classes.wrapper}
			ref={constraintsRef}
			onMouseMove={handleMouseMove}
		>
			<div className={classes.content}>
				<ul className={classes.categories}>
					{category.map(({ name, thumbnailPath, id }) => {
						const isSale = id === -1;
						const isAll = id === ALL_CATEGORY_ID;

						let link = `category/${id}`;

						if (isSale) {
							link = 'sale';
						}

						if (isAll) {
							link = 'all';
						}
						return (
							<Filter
								key={uuidv4()}
								title={name}
								status={isSale ? 'sale' : 'category'}
								image={thumbnailPath ? getImagePath(thumbnailPath) : undefined}
								link={link}
								index={id}
								handleMouseLeave={handleMouseLeave}
								handleMouseInteraction={handleMouseInteraction}
								hoveredIndex={hoveredIndex}
								hoveredImage={hoveredImage}
								x={x}
								y={y}
							/>
					)})}

					{brand.map(({ name, thumbnailPath, id }) => (
						<Filter
							key={uuidv4()}
							title={name}
							status={'brand'}
							image={getImagePath(thumbnailPath)}
							link={`brand/${id}`}
							index={id}
							handleMouseLeave={handleMouseLeave}
							handleMouseInteraction={handleMouseInteraction}
							hoveredIndex={hoveredIndex}
							hoveredImage={hoveredImage}
							x={x}
							y={y}
						/>
					))}

				</ul>
			</div>
		</div>
	);
};
