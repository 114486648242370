import { ReactNode } from "react";
import classes from './Dialog.module.scss';

type DialogProps = {
	children: ReactNode;
    top: string,
    left: string,
}

export const Dialog = (props: DialogProps) => {
    const { children } = props;

    return (
        <div className={classes.background}>

            <div
                className={classes.dialog}
                style={{
                    position: 'absolute',
                    top: props.top,
                    left: props.left,
                }}>
                {children}
            </div>
        </div>
    )
}